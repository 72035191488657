/* eslint-disable no-unused-vars */
import React from "react";
import { IClassroomCourse } from "types/helpers/classroomTypes";

import { Box, CircularProgress, Typography } from "@material-ui/core";
import { IMsTeamsGroup } from "types/helpers/msTeamsTypes";
import { useSelector } from "react-redux";
import {
  getClassroomCourses,
  getClassroomFetchingData,
} from "redux/import_students/google_classroom/google_classroom_students_selectors";
import {
  getMsTeamsFetchingData,
  getMsTeamsGroups,
} from "redux/import_students/microsoft_teams/microsoft_teams_students_selectors";
import css from "./course_list.module.scss";
import { CourseCard } from "../course_card/course_card";
import { ExternalResource } from "../add_from_external_resources";

interface ICourseList {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  type: ExternalResource;
  userToken?: string;
}

export const CourseList = ({
  type,
  setCurrentStep,
  userToken,
}: ICourseList) => {
  const courses = useSelector(getClassroomCourses);
  const isFetchingClassroomData = useSelector(getClassroomFetchingData);

  const groups = useSelector(getMsTeamsGroups);
  const isFetchingMsTeamsData = useSelector(getMsTeamsFetchingData);

  const { GOOGLE_CLASSROOM, MS_TEAMS } = ExternalResource;

  switch (type) {
    case GOOGLE_CLASSROOM: {
      return (
        <>
          <h3 className={css.header}>Select a Course </h3>
          <Box className={css.wrapper}>
            {!isFetchingClassroomData && courses.length < 0 && (
              <Typography>No courses found.</Typography>
            )}
            {!isFetchingClassroomData ? (
              courses.map((course: IClassroomCourse) => (
                <CourseCard
                  type={type}
                  key={course.id}
                  courseId={course.id}
                  courseName={course.name}
                  setCurrentStep={setCurrentStep}
                  userToken={userToken}
                />
              ))
            ) : (
              <span className={css.loader_wrapper}>
                <CircularProgress />
              </span>
            )}
          </Box>
        </>
      );
    }
    case MS_TEAMS: {
      return (
        <>
          <h3 className={css.header}>Select a Course </h3>
          <Box className={css.wrapper}>
            {!isFetchingMsTeamsData && groups.length < 0 && (
              <Typography>No groups found.</Typography>
            )}
            {!isFetchingMsTeamsData ? (
              groups.map((group: IMsTeamsGroup) => (
                <CourseCard
                  type={type}
                  key={group.id}
                  courseId={group.id}
                  courseName={group.displayName}
                  setCurrentStep={setCurrentStep}
                />
              ))
            ) : (
              <span className={css.loader_wrapper}>
                <CircularProgress />
              </span>
            )}
          </Box>
        </>
      );
    }
    default: {
      return null;
    }
  }
};
