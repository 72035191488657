import { Provider } from "react-redux";
import { createTheme } from "@material-ui/core/styles/";
import { ThemeProvider } from "@material-ui/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ConnectedRouter } from "connected-react-router";
import { CLIENT_ID } from "constants/keys";

import {
  faPlay,
  faPause,
  faChevronLeft,
  faChevronRight,
  faRedoAlt,
  faTimes,
  faCheck,
  faInfoCircle,
  faUser,
  faCaretDown,
  faCaretUp,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { store as reduxStore, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AsyncStripeProvider } from "./stripe/async_stripe_provider";
import App from "./App";
import { history } from "../history_";

// TODO: remove any
library.add(
  faInfoCircle as any,
  faPlay as any,
  faPause as any,
  faChevronLeft as any,
  faChevronRight as any,
  faRedoAlt as any,
  faTimes as any,
  faCheck as any,
  faUser as any,
  faCaretDown as any,
  faCaretUp as any,
  faQuestionCircle as any,
);

const theme = createTheme({
  overrides: {},
  palette: {
    secondary: {
      main: "#9c80f4",
    },
  },
});

export const Root = ({ store }: { store: typeof reduxStore }) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AsyncStripeProvider>
            <GoogleOAuthProvider clientId={CLIENT_ID}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </GoogleOAuthProvider>
          </AsyncStripeProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};
