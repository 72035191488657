import { AxiosError } from "axios";
import { castDateToLocal } from "helpers/timestampToSince";
import _ from "lodash";
import { toast } from "react-toastify";
import { History } from "history";
import {
  getUserEmailSelector,
  getUserIdSelector,
} from "redux/auth/user_selectors";
import { IState } from "redux/store";
import { Subset } from "types/redux_";
import { SONGBUILDER_DATA } from "components/songwriting/songbuilder/songbuilder_data";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";

import { songwritingApi } from "./songwriting_api";
import {
  ICustomSong,
  ICustomSongLyrics,
  ISongwriting,
  SongStructureType,
  SongContent,
  TableDataProps,
  ActualTableDataProps,
  EnumDragAndDropActions,
  ISharedSong,
  TABS,
  IOrgSong,
  IMobileSidebar,
} from "./songwriting_interfaces";
import { getCurrentCustomSong, getCustomSongId } from "./songwriting_selectors";

export const customSongInitialState = {
  artist: "Unknown user",
  explicit: false,
  edited_at: new Date(),
  viewed_at: new Date(),
  length: 0,
  time_signature: "4/4",
  custom_song_key: "A",
  song_name: "New Song Title",
  tempo: 120,
  tuning: "",
  shared_songs: null,
  user_id: null,
  note: "",
  chord_icon_style: "Basic",
  cert_number: null,
  custom_song_lyrics: [
    {
      song_structure: "Verse" as SongStructureType,
      song_lyrics: "",
      song_chords: "",
      position: 0,
    },
  ],
  custom_song_assets: [
    {
      id: -1,
      url: "",
      rec_minutes: 0,
      rec_seconds: 0,
      createdAt: new Date(),
    },
  ],
  backing_track_playing: false,
};

type SongType = "Shared" | "Custom";

const DIFFERENCE_BETWEEN_TWO_INPUTS = 2;
export interface ISongwritingState {
  customSongs: ISongwriting[];
  selectedSongId: number;
  customSelectedSong: ISongwriting | ICustomSong;
  selectedSongBucket: ICustomSongLyrics;
  activeTab: number;
  songType: SongType;
  isDraggedChord: boolean;
  customSongTemplates: ISongwriting[];

  tipsList: string[];
  collapsedStructures: number[];
  draggableItem: SongContent | null;
  tableData: TableDataProps[];
  actualTableData: ActualTableDataProps[];
  idSongStructureWhenReplaceChordInList: number | null;
  countOfInputsAfterDeleteRow: number | null;
  typeOfDragAndDropAction: EnumDragAndDropActions | null;
  replacedSongUnit: ICustomSongLyrics | null;
  idOfStructureToFocus: number | null;
  sharedWithMeSong: ISharedSong[] | null;
  orgSongs: IOrgSong[] | [];
  activeOrgSongId: number | null;
  mobileSidebar: IMobileSidebar;
  usingMobileAppFlag: boolean | null;
  backing_track_playing: boolean;
}

export const initialState: ISongwritingState = {
  customSongs: [],
  selectedSongId: 0,
  songType: "Custom",
  customSelectedSong: customSongInitialState,
  selectedSongBucket: {
    id: 0,
    position: 0,
    song_structure: "Verse" as SongStructureType,
    song_chords: "",
    song_lyrics: "",
  },
  activeTab: 0,
  isDraggedChord: false,
  customSongTemplates: [],

  tipsList: [],
  collapsedStructures: [],
  draggableItem: null,
  tableData: [],
  actualTableData: [],
  idSongStructureWhenReplaceChordInList: null,
  countOfInputsAfterDeleteRow: null,
  typeOfDragAndDropAction: null,
  replacedSongUnit: null,
  idOfStructureToFocus: null,
  sharedWithMeSong: [],
  orgSongs: [],
  activeOrgSongId: null,
  mobileSidebar: { show: "none", content: null },
  usingMobileAppFlag: false,
  backing_track_playing: false,
};

export const fetchCustomSongTemplates = createAsyncThunk<
  { customSongTemplates: ISongwriting[] } | null,
  void,
  { state: IState }
>("songwriting/fetchCustomSongTemplates", async (_, { getState, dispatch }) => {
  const { data } = await songwritingApi.fetchCustomSongTemplates();
  return { customSongTemplates: data };
});

export const fetchCustomSongs = createAsyncThunk<
  { customSongs: ISongwriting[] } | null,
  void,
  { state: IState }
>("songwriting/fetchCustomSongs", async (_, { getState, dispatch }) => {
  const userId = getUserIdSelector(getState());
  const songType = getState().songwriting.activeTab;

  if (songType === 1) {
    dispatch(fetchSharedSongs());
    return null;
  }

  if (userId) {
    const { data } = await songwritingApi.fetchUsersCustomSongs(userId);

    // Cast UTC Time format to Local Time
    const dateOffset = new Date().getTimezoneOffset();

    data.forEach((item) => {
      if (item.viewed_at) {
        item.viewed_at = castDateToLocal(item.viewed_at, dateOffset);
      }
      if (item.edited_at) {
        item.edited_at = castDateToLocal(item.edited_at, dateOffset);
      }
    });

    // Sort songs by createdAt
    const sortedData = data.sort(
      (a: ISongwriting, b: ISongwriting) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return { customSongs: sortedData };
  }
  return { customSongs: [] };
});

export const fetchOrgSongs = createAsyncThunk<
  { data: any },
  { orgId: number; excludeUserId: number },
  { state: IState }
>(
  "songwriting/fetchOrgSongs",
  async ({ orgId, excludeUserId }, { getState }) => {
    const { data } = await songwritingApi.fetchOrgCustomSongs(
      orgId,
      excludeUserId,
    );
    return { data };
  },
);

export const fetchSharedSongs = createAsyncThunk<
  { sharedSongs: ISharedSong[] },
  void,
  { state: IState }
>("songwriting/fetchSharedSongs", async (_, { getState }) => {
  const userEmail = getUserEmailSelector(getState());
  if (userEmail) {
    const { data } = await songwritingApi.fetchUserSharedSongs(userEmail);
    // Sort songs by createdAt

    const dateOffset = new Date().getTimezoneOffset();
    data.forEach((item) => {
      if (item.viewedAt) {
        item.viewedAt = castDateToLocal(item.viewedAt, dateOffset);
      }
      if (item.editedAt) {
        item.editedAt = castDateToLocal(item.editedAt, dateOffset);
      }
    });

    const sortedData = data.sort(
      (a: ISharedSong, b: ISharedSong) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    return { sharedSongs: sortedData };
  }
  return { sharedSongs: [] };
});

export const deleteCustomSong = createAsyncThunk<
  void,
  number,
  { state: IState }
>("songwriting/createCustomSong", async (song_id, { getState, dispatch }) => {
  try {
    const { data } = await songwritingApi.deleteCustomSong(song_id);
    if (data) {
      dispatch(fetchCustomSongs());
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

export const createCustomSong = createAsyncThunk<
  number | void,
  {
    user_id: number;
    artist: string;
    template?: ISongwriting;
    noteText?: string | null;
    lyricText?: string | null;
    title?: string | null;
  },
  { state: IState }
>("songwriting/createCustomSong", async (songData, { getState, dispatch }) => {
  try {
    if (songData.user_id === null) {
      throw new Error("No user was found");
    }

    if (!songData.noteText) {
      songData.noteText = null;
    }

    if (!songData.lyricText) {
      songData.lyricText = null;
    }

    const getDefaultLyrics = () => {
      if (songData.lyricText) {
        return [
          {
            song_structure: "Verse",
            song_lyrics: songData.lyricText?.split("\n").join("/n"),
            song_chords: "",
            position: 0,
          },
        ];
      }

      if (songData.template) {
        return songData.template.custom_song_lyrics.map((verse) => {
          return {
            song_structure: verse.song_structure,
            song_lyrics: verse.song_lyrics,
            song_chords: verse.song_chords,
            position: verse.position,
          };
        });
      }

      return customSongInitialState.custom_song_lyrics;
    };

    const defaultCustomSongLyrics = getDefaultLyrics();

    const defaultCustomSongKey = songData.template
      ? songData.template.custom_song_key
      : customSongInitialState.custom_song_key;

    const defaultCustomSongTempo = songData.template
      ? Math.trunc(songData.template.tempo)
      : customSongInitialState.tempo;

    const defaultTimeSignature = songData.template
      ? songData.template.time_signature
      : customSongInitialState.time_signature;

    const default_chord_icon_style = songData.template
      ? songData.template.chord_icon_style
      : customSongInitialState.chord_icon_style;

    const defaultTitle = songData.title
      ? songData.title
      : customSongInitialState.song_name;

    const songTemplate = {
      ...customSongInitialState,
      user_id: songData.user_id,
      artist: songData.artist,
      edited_at: new Date(),
      viewed_at: new Date(),
      song_name: defaultTitle,
      custom_song_lyrics: defaultCustomSongLyrics,
      custom_song_key: defaultCustomSongKey,
      tempo: defaultCustomSongTempo,
      time_signature: defaultTimeSignature,
      chord_icon_style: default_chord_icon_style,
      note: songData.noteText,
    };

    const { data } = await songwritingApi.createCustomSong({
      ...songTemplate,
    });

    if (data) {
      await dispatch(fetchCustomSongs());
      dispatch(
        setCurrentSongId({ id: getState().songwriting.customSongs[0].id }),
      );
      return getState().songwriting.customSongs[0].id;
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

export const updateCustomSong = createAsyncThunk<
  void,
  Subset<ICustomSong>,
  { state: IState }
>("songwriting/updateCustomSong", async (songData, { getState, dispatch }) => {
  try {
    let id;
    let currentSong;
    if (songData.id) {
      id = songData.id;
      currentSong = getState().songwriting.customSongs.find((customSong) => {
        return customSong.id === songData.id;
      });
      delete songData.id;
    } else {
      id = getCustomSongId(getState());
      currentSong = getCurrentCustomSong(getState());
    }

    const userId = getUserIdSelector(getState());

    if (currentSong && currentSong.user_id !== userId) {
      songData.user_id = userId;
    }

    const updateInfo = { ...songData, edited_at: new Date() };

    const { data } = await songwritingApi.updateCustomSong(id, updateInfo);

    if (data && currentSong) {
      if (currentSong.user_id !== userId) {
        dispatch(fetchSharedSongs());
      }
      dispatch(fetchCustomSongs());
      if (
        songData?.custom_song_lyrics![0]?.type ===
        EnumDragAndDropActions.createStructure
      ) {
        dispatch(setIdOfStructureToFocus(data.id));
      }
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data?.message || err);
  }
});

export const deleteCustomSongUnit = createAsyncThunk<
  void,
  { unit_id: number; userEmail?: string },
  { state: IState }
>(
  "songwriting/deleteCustomSongUnit",
  async ({ unit_id, userEmail }, { getState, dispatch }) => {
    try {
      const id = getCustomSongId(getState());
      const deleteInfo = { unit_id, edited_at: new Date(), userEmail };

      const { data } = await songwritingApi.deleteCustomSongUnit(
        id,
        deleteInfo,
      );
      if (data) {
        if (userEmail) {
          dispatch(fetchSharedSongs());
        }
        dispatch(fetchCustomSongs());
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const updateCustomSongUnitPositions = createAsyncThunk<
  void,
  {
    order_data: { position: number; song_structure_id: number }[];
    edited_at?: Date;
    userEmail?: string;
  },
  { state: IState }
>(
  "songwriting/updateCustomSongPositions",
  async (songData, { getState, dispatch }) => {
    try {
      const id = getCustomSongId(getState());
      const updateInfo = { ...songData, edited_at: new Date() };

      const { data } = await songwritingApi.updateCustomSongUnitOrder(
        id,
        updateInfo,
      );
      if (data) {
        if (songData.userEmail) {
          dispatch(fetchSharedSongs());
        }
        dispatch(fetchCustomSongs());
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const addAssetToCustomSong = createAsyncThunk<
  void,
  {
    rec_seconds: number;
    rec_minutes: number;
    title: string;
    audioBlob: Blob;
  },
  { state: IState }
>(
  "songwriting/addAssetPathToCustomSong",
  async (assetData, { getState, dispatch }) => {
    try {
      const id = getCustomSongId(getState());

      const data = await songwritingApi.addAssetToCustomSong(id, assetData);
      if (data) {
        const updateInfo = { edited_at: new Date() };

        await songwritingApi.updateCustomSong(id, updateInfo);

        dispatch(fetchCustomSongs());

        mixpanel.track("Create Song Audio");
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const changeAssetTitle = createAsyncThunk<
  void,
  { id: number; title: string },
  { state: IState }
>(
  "songwriting/changeAssetTitle",
  async ({ id, title }, { getState, dispatch }) => {
    try {
      const { data } = await songwritingApi.changeAssetTitle(id, title);

      if (data) {
        const customSongId = getCustomSongId(getState());
        const updateInfo = { edited_at: new Date() };

        await songwritingApi.updateCustomSong(customSongId, updateInfo);

        dispatch(fetchCustomSongs());
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const deleteCustomSongAsset = createAsyncThunk<
  void,
  string,
  { state: IState }
>(
  "songwriting/addAssetPathToCustomSong",
  async (assetUrl, { getState, dispatch }) => {
    try {
      const id = getCustomSongId(getState());

      await songwritingApi.deleteCustomSongAsset(id, assetUrl);

      dispatch(fetchCustomSongs());
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const updateCustomSongLyricsById = createAsyncThunk<
  void,
  { id: number; song_lyrics: string },
  { state: IState }
>(
  "songwriting/updateCustomSongLyricsById",
  async ({ id, song_lyrics }, { getState, dispatch }) => {
    try {
      const userId = getUserIdSelector(getState());
      await songwritingApi.updateCustomSongUnitLyricsById(id, song_lyrics);
      userId &&
        dispatch(
          changeEditedAtStatus({
            user_id: userId,
            edited_at: new Date(),
          }),
        );
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const updateCustomSongChords = createAsyncThunk<
  void,
  { id: number; song_chords: string },
  { state: IState }
>(
  "songwriting/updateCustomSongChords",
  async ({ id, song_chords }, { getState, dispatch }) => {
    try {
      const userId = getUserIdSelector(getState());
      await songwritingApi.updateCustomSongUnitChordsById(id, song_chords);
      userId &&
        dispatch(
          changeEditedAtStatus({
            user_id: userId,
            edited_at: new Date(),
          }),
        );
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const updateCustomSongLyrics = createAsyncThunk<
  void,
  string | undefined,
  { state: IState }
>(
  "songwriting/updateCustomSongLyrics",
  async (type, { getState, dispatch }) => {
    try {
      const id = getCustomSongId(getState());

      const newSongBucket = getState().songwriting.selectedSongBucket;

      const updateInfo = {
        custom_song_lyrics: [newSongBucket],
        edited_at: new Date(),
      };
      await songwritingApi.updateCustomSong(id, updateInfo);
    } catch (err) {
      toast.error((err as AxiosError).response?.data?.message || err);
    }
  },
);

export const deleteInvitee = createAsyncThunk<
  void,
  { email: string; shared_song_id: number },
  { state: IState }
>(
  "songwriting/deleteInvitees",
  async ({ email, shared_song_id }, { dispatch }) => {
    try {
      const { status } = await songwritingApi.deleteInvitee(
        email,
        shared_song_id,
      );
      if (status) {
        toast.success("User's been deleted from the song");
        dispatch(fetchCustomSongs());
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data.message || err);
    }
  },
);

export const acceptSongSharingInvitation = createAsyncThunk<
  void,
  { invitationToken: string; history: History },
  { state: IState }
>(
  "songwriting/acceptSongSharingInvitation",
  async ({ invitationToken, history }, { dispatch }) => {
    try {
      const { data } = await songwritingApi.acceptInvitation(invitationToken);

      if (data) {
        history.push("/songwriting");
        dispatch(setTabs(TABS.SHARED));
        toast.success("Invitation has been accepted!");
      }
    } catch (err) {
      toast.error((err as AxiosError).response?.data.message || err);
    }
  },
);

export const shareSongByEmails = createAsyncThunk<
  void,
  { song_id: number; sender_id: number; recipient_emails: string[] },
  { state: IState }
>("songwriting/shareSongByEmails", async (payload, { getState, dispatch }) => {
  try {
    const { data } = await songwritingApi.shareSongByEmails(payload);
    if (data) {
      dispatch(fetchCustomSongs());
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data.message || err);
  }
});

export const changeViewedAtStatus = createAsyncThunk<
  void,
  { user_id: number | null; viewed_at: Date },
  { state: IState }
>("songwriting/changedViewedAtStatus", async (date, { getState, dispatch }) => {
  try {
    const id = getCustomSongId(getState());
    const userId = getUserIdSelector(getState());
    const currentSong = getCurrentCustomSong(getState());

    const { data } = await songwritingApi.updateCustomSong(id, date);

    if (data) {
      if (currentSong.user_id !== userId) {
        dispatch(fetchSharedSongs());
      }
      dispatch(fetchCustomSongs());
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data.message || err);
  }
});

export const changeEditedAtStatus = createAsyncThunk<
  void,
  { user_id: number | null; edited_at: Date },
  { state: IState }
>("songwriting/changedViewedAtStatus", async (date, { getState, dispatch }) => {
  try {
    const id = getCustomSongId(getState());
    const { data } = await songwritingApi.updateCustomSong(id, date);
    if (data) {
      dispatch(fetchCustomSongs());
    }
  } catch (err) {
    toast.error((err as AxiosError).response?.data.message || err);
  }
});

const songwritingSlice = createSlice({
  name: "songwriting",
  initialState,
  reducers: {
    setCurrentSongId: (state, action: PayloadAction<{ id: number }>) => {
      state.selectedSongId = action.payload.id;
      state.customSelectedSong =
        state.customSongs.find((elem) => elem.id === state.selectedSongId) ||
        customSongInitialState;
    },

    setCurrentSong: (
      state,
      action: PayloadAction<{ custom_song: ICustomSong }>,
    ) => {
      state.customSelectedSong =
        action.payload.custom_song || customSongInitialState;
    },

    clearCurrentSong: (state) => {
      state.selectedSongId = 0;
      state.customSelectedSong = customSongInitialState;
    },

    setSelectedSongBucket: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const emptyBucket: ICustomSongLyrics = {
        song_chords: "",
        song_lyrics: "",
        song_structure: "Verse",
        position: 0,
      };
      state.selectedSongBucket =
        state.customSelectedSong.custom_song_lyrics.find(
          (obj) => obj.id === id,
        ) || emptyBucket;
    },

    updateCustomSelectedSongLyrics: (
      state,
      action: PayloadAction<{
        song_lyrics_id: number | undefined;
        song_structure: string;
      }>,
    ) => {
      const { song_lyrics_id, song_structure } = action.payload;
      const indexOfCurrentStructure =
        state.customSelectedSong.custom_song_lyrics.findIndex(
          (item) => item.id === song_lyrics_id,
        );

      state.customSelectedSong.custom_song_lyrics[
        indexOfCurrentStructure
      ].song_structure = song_structure;
    },

    updateCustomSelectedSong: (
      state,
      action: PayloadAction<{
        tempo?: number;
        time_signature?: string;
        custom_song_key?: string;
        chord_icon_style?: string;
      }>,
    ) => {
      const { tempo, time_signature, custom_song_key, chord_icon_style } =
        action.payload;
      tempo && (state.customSelectedSong.tempo = tempo);
      time_signature &&
        (state.customSelectedSong.time_signature = time_signature);
      custom_song_key &&
        (state.customSelectedSong.custom_song_key = custom_song_key);
      chord_icon_style &&
        (state.customSelectedSong.chord_icon_style = chord_icon_style);
    },

    updateCurrentSongLyricsBucket: (
      state,
      action: PayloadAction<{
        song_structure?: string;
        song_lyrics?: string;
        song_chords?: string;
      }>,
    ) => {
      state.selectedSongBucket = {
        ...state.selectedSongBucket,
        ...action.payload,
      };
    },

    setTabs: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },

    setIsDraggedChord: (state, action: PayloadAction<boolean>) => {
      state.isDraggedChord = action.payload;
    },

    pushTipsList: (state, action: PayloadAction<string>) => {
      const MAX_TIPS = 20;
      while (state.tipsList.length > MAX_TIPS) {
        state.tipsList.shift();
      }
      state.tipsList.push(action.payload);
    },

    popTipsList: (state) => {
      state.tipsList.pop();
    },

    updateTipNote: (
      state,
      action: PayloadAction<{
        tipNoteValue: string;
        songId: number;
      }>,
    ) => {
      const customSong = state.customSongs.find((item) => {
        return item.id === action.payload.songId;
      });
      if (customSong !== undefined) {
        customSong.note = action.payload.tipNoteValue;
      }
    },

    setCollapsedStructures: (
      state,
      action: PayloadAction<{
        structureId: number;
      }>,
    ) => {
      const { structureId } = action.payload;

      const isAlreadyInList = state.collapsedStructures.find(
        (item) => item === structureId,
      );
      if (isAlreadyInList) {
        _.pull(state.collapsedStructures, structureId);
        return;
      }
      state.collapsedStructures.push(structureId);
    },

    updateCurrentCustomSongLyrics: (
      state,
      action: PayloadAction<{
        id: number;
        value: string;
      }>,
    ) => {
      const { id, value } = action.payload;
      const indexOfCurrentStructure =
        state.customSelectedSong.custom_song_lyrics.findIndex(
          (item) => item.id === id,
        );
      state.customSelectedSong.custom_song_lyrics[
        indexOfCurrentStructure
      ].song_lyrics = value;
    },

    setTableData: (state, action: PayloadAction<TableDataProps>) => {
      const { id, data } = action.payload;
      const isNeedToUpdateTableData = state.tableData.find(
        (item) => item.id === id,
      );

      if (isNeedToUpdateTableData) {
        const indexOfTableDataToUpdate = state.tableData.findIndex(
          (item) => item.id === id,
        );
        state.tableData[indexOfTableDataToUpdate] = { ...action.payload };
        state.actualTableData[indexOfTableDataToUpdate] = {
          id,
          data,
        };
        return;
      }

      state.tableData?.push(action.payload);
      state.actualTableData?.push({ id, data });
    },

    updateActualTableDataRows: (
      state,
      action: PayloadAction<{
        songUnitId: number;
        index: number;
        inputValue: string;
      }>,
    ) => {
      const { songUnitId, index, inputValue } = action.payload;
      const songUnitTableId = state.actualTableData.findIndex(
        (item) => item.id === songUnitId,
      );
      state.actualTableData[songUnitTableId].data[index].c0 = inputValue;
    },

    updateTableDataRows: (
      state,
      action: PayloadAction<{
        songUnitId: number;
        index: number;
        inputValue: string;
      }>,
    ) => {
      const { songUnitId, index, inputValue } = action.payload;
      const songUnitTableId = state.actualTableData.findIndex(
        (item) => item.id === songUnitId,
      );
      state.actualTableData[songUnitTableId].data[index].c0 = inputValue;
      state.tableData[songUnitTableId].data[index].c0 = inputValue;
    },

    syncTableDataRows: (
      state,
      action: PayloadAction<{ songUnitId: number }>,
    ) => {
      const { songUnitId } = action.payload;
      const songUnitTableId = state.actualTableData.findIndex(
        (item) => item.id === songUnitId,
      );
      state.actualTableData[songUnitTableId].data.forEach((value, key) => {
        state.tableData[songUnitTableId].data[key].c0 = value.c0;
      });
    },

    clearTableData: (state) => {
      state.tableData = [];
      state.actualTableData = [];
    },

    pasteIntoTable: (
      state,
      action: PayloadAction<{
        songUnitId: number;
        pasteArray: [string];
        pastePosition: number;
      }>,
    ) => {
      const { songUnitId, pasteArray, pastePosition } = action.payload;
      const indexOfTableDataToUpdate = state.tableData.findIndex(
        (item) => item.id === songUnitId,
      );
      const table = state.tableData[indexOfTableDataToUpdate];
      const actualTable = state.actualTableData[indexOfTableDataToUpdate];
      const emptyChordRow = { ...SONGBUILDER_DATA.droppableRowData };
      let pastePositionOffset = 0;

      pasteArray.forEach((data: string, key: number) => {
        const currentPastePosition = pastePosition + pastePositionOffset;
        if (key === 0) {
          actualTable.data[pastePosition].c0 = data;

          table.data[pastePosition].c0 = data;
        } else {
          const lyricRow = { c0: data };

          table.data.splice(currentPastePosition - 1, 0, emptyChordRow);
          table.data.splice(currentPastePosition, 0, lyricRow);

          actualTable.data.splice(currentPastePosition - 1, 0, emptyChordRow);
          actualTable.data.splice(currentPastePosition, 0, lyricRow);
        }
        pastePositionOffset += DIFFERENCE_BETWEEN_TWO_INPUTS;
      });
    },

    createNewRowTable: (
      state,
      action: PayloadAction<{
        songUnitId: number;
        position?: number;
      }>,
    ) => {
      const { songUnitId, position } = action.payload;
      const indexOfTableDataToUpdate = state.tableData.findIndex(
        (item) => item.id === songUnitId,
      );
      state.tableData[indexOfTableDataToUpdate].data.push(
        {
          ...SONGBUILDER_DATA.droppableRowData,
        },
        {
          c0: "",
        },
      );
      state.actualTableData[indexOfTableDataToUpdate].data.push(
        {
          ...SONGBUILDER_DATA.droppableRowData,
        },
        {
          c0: "",
        },
      );
      if (position) {
        const tableDataLength =
          state.tableData[indexOfTableDataToUpdate].data.length;

        for (let i = tableDataLength - 1; i > position + 2; i -= 2) {
          state.tableData[indexOfTableDataToUpdate].data[i] = {
            ...state.tableData[indexOfTableDataToUpdate].data[i - 2],
          };
          state.actualTableData[indexOfTableDataToUpdate].data[i] = {
            ...state.actualTableData[indexOfTableDataToUpdate].data[i - 2],
          };
        }
        state.tableData[indexOfTableDataToUpdate].data[position + 2].c0 = "";
        state.actualTableData[indexOfTableDataToUpdate].data[position + 2].c0 =
          "";
      }
    },

    setDraggableItem: (state, action: PayloadAction<SongContent | null>) => {
      state.draggableItem = action.payload;
    },

    deleteRowTable: (
      state,
      action: PayloadAction<{ index: number; songUnitId: number }>,
    ) => {
      const { index, songUnitId } = action.payload;
      const bucketId = state.tableData.findIndex(
        (item) => item.id === songUnitId,
      );
      const newTableData = [...state.actualTableData[bucketId].data];
      newTableData.splice(index - 1, 2);

      state.tableData[bucketId].data = newTableData;
      state.actualTableData[bucketId].data = newTableData;
      state.countOfInputsAfterDeleteRow = newTableData.length;
    },

    replaceDataInTable: (
      state,
      action: PayloadAction<{ destinationIndex: number; dataString: string }>,
    ) => {
      const { destinationIndex } = action.payload;

      const newTableData = [...state.actualTableData[destinationIndex].data];
      newTableData.splice(destinationIndex - 1, 2);

      state.tableData[destinationIndex].data = newTableData;
      state.actualTableData[destinationIndex].data = newTableData;
      state.countOfInputsAfterDeleteRow = newTableData.length;
    },

    updateChordsInTable: (
      state,
      action: PayloadAction<{ id: number; songChords: string }>,
    ) => {
      const { id, songChords } = action.payload;
      const indexOfTableToUpdateChords = state.tableData.findIndex(
        (item) => item.id === id,
      );

      if (indexOfTableToUpdateChords === -1) {
        return;
      }

      state.tableData[indexOfTableToUpdateChords].songChords = songChords;
    },

    setCountOfInputsAfterDeleteRow: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.countOfInputsAfterDeleteRow = action.payload;
    },

    setIdSongStructureWhenReplaceChordInList: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.idSongStructureWhenReplaceChordInList = action.payload;
    },

    setTypeOfDragAndDropAction: (
      state,
      action: PayloadAction<EnumDragAndDropActions | null>,
    ) => {
      state.typeOfDragAndDropAction = action.payload;
    },

    setIsReplacingSongStructure: (
      state,
      action: PayloadAction<ICustomSongLyrics | null>,
    ) => {
      state.replacedSongUnit = action.payload;
    },

    setIdOfStructureToFocus: (state, action: PayloadAction<number | null>) => {
      state.idOfStructureToFocus = action.payload;
    },

    setSharedWithMeSong: (
      state,
      action: PayloadAction<ISharedSong[] | null>,
    ) => {
      state.sharedWithMeSong = action.payload;
    },

    setActiveOrgSongId: (state, action) => {
      state.activeOrgSongId = action.payload;
    },

    toggleMobileSidebar: (state) => {
      state.mobileSidebar.show =
        state.mobileSidebar.show === "none" ? "block" : "none";
    },

    setMobileSidebarContent: (state, action) => {
      state.mobileSidebar.content = action.payload;
    },

    setUsingMobileAppFlag: (state, action) => {
      state.usingMobileAppFlag = action.payload;
    },

    toggleBackingTrackPlaying: (state) => {
      state.backing_track_playing = !state.backing_track_playing;
    },

    setBackingTrackPlaying: (state, action) => {
      state.backing_track_playing = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCustomSongs.fulfilled, (state, action) => {
      if (action.payload) {
        state.customSongs = action.payload.customSongs;
        if (state.selectedSongId) {
          state.customSelectedSong =
            state.customSongs.find(
              (elem) => elem.id === state.selectedSongId,
            ) || customSongInitialState;
        }
      }
    });
    builder.addCase(fetchOrgSongs.fulfilled, (state, action) => {
      state.orgSongs = action.payload.data;
    });
    builder.addCase(fetchSharedSongs.fulfilled, (state, action) => {
      state.sharedWithMeSong = action.payload.sharedSongs;

      if (state.selectedSongId) {
        state.customSelectedSong =
          state.sharedWithMeSong.find(
            (elem) => elem.sharedSong.custom_song.id === state.selectedSongId,
          )?.sharedSong.custom_song || customSongInitialState;
      }
    });
    builder.addCase(fetchCustomSongTemplates.fulfilled, (state, action) => {
      if (action.payload) {
        state.customSongTemplates = action.payload.customSongTemplates;
      }
    });
  },
});

export const songwritingActions = songwritingSlice.actions;

export const {
  actions: {
    setCurrentSongId,
    setCurrentSong,
    clearCurrentSong,
    updateCurrentSongLyricsBucket,
    updateCustomSelectedSongLyrics,
    updateTableDataRows,
    setSelectedSongBucket,
    setTabs,
    setIsDraggedChord,
    setCollapsedStructures,
    updateCurrentCustomSongLyrics,
    setDraggableItem,
    setTableData,
    clearTableData,
    updateActualTableDataRows,
    pasteIntoTable,
    createNewRowTable,
    setIdSongStructureWhenReplaceChordInList,
    deleteRowTable,
    updateChordsInTable,
    setCountOfInputsAfterDeleteRow,
    setTypeOfDragAndDropAction,
    setIsReplacingSongStructure,
    setIdOfStructureToFocus,
    setActiveOrgSongId,
    toggleMobileSidebar,
    setMobileSidebarContent,
    setUsingMobileAppFlag,
    pushTipsList,
    popTipsList,
    updateTipNote,
    syncTableDataRows,
    updateCustomSelectedSong,
    toggleBackingTrackPlaying,
    setBackingTrackPlaying,
  },
  reducer: songwritingReducer,
} = songwritingSlice;
