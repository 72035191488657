import { isActionRejectedHelper } from "helpers/is_action_rejected_helper";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { stepListActions } from "redux/step_list/stepListSlice";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { closeModal } from "redux/ui/modal/modal_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";
import { IState } from "redux/store";
import { UPGRADE } from "routes/route_constants";
import { getLocation } from "connected-react-router";
import { IStudentWithSkills } from "types/models/student_with_skills";
import { MIN_INPUT_FOR_HELP } from "components/settings/hidden_lessons/hidden_lessons";
import { fetchOrganizations } from "redux/org_management/org_management_slice";
import { setProductPrice } from "redux/entities/stripe/stripe_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import { getPricePerStudent } from "redux/entities/stripe/stripe_selectors";
import { getRoleSelector } from "redux/auth/roles/roles_selector";
import {
  IHiddenSongs,
  IHiddenSongsApi,
  IOrganization,
  organizationApi,
} from "./organization_api";

import {
  fetchUserOrganizations,
  setUsersOrganizations,
} from "./organization_courses_common_actions";
import {
  getOrganizationId,
  getFirstOrganization,
} from "./organization_selectors";

export interface IUpdateLogo {
  logoPath: string;
}

export interface IUploadLogo {
  logo: File;
}

interface IStaffTabStatus {
  mode: "tab" | "staff";
  orgId?: number;
}

interface ICreateOrganization {
  email: string;
  orgName: string;
  max_seats?: number;
}

export interface IAxiosErr {
  response: {
    status: number;
    data: { message?: string; errors?: string; error?: string };
  };
}

export const createOrganizationForTeacher = createAsyncThunk<
  void,
  ICreateOrganization,
  { state: IState }
>(
  "organization/createOrganizationForTeacher",
  async ({ email, orgName, max_seats = 0 }, { dispatch, getState }) => {
    try {
      if (orgName) {
        const result = await dispatch(
          createOrganization({ email, orgName, max_seats }),
        );

        if (isActionRejectedHelper(result)) {
          throw result;
        }
      }

      const userId = getUserIdSelector(getState());
      if (userId) {
        dispatch(fetchUserOrganizations({ userId }));
      }
      dispatch(closeModal());

      const role = getRoleSelector(getState());

      const { pathname } = getLocation(getState());

      // set stripe data for payment
      const pricePerStudent = getPricePerStudent(getState());
      if (
        pricePerStudent &&
        (role === ROLES.PAID_TEACHER_ACCOUNT || pathname === UPGRADE)
      ) {
        const totalPrice = (max_seats * pricePerStudent).toString();
        dispatch(setProductPrice(totalPrice));
      }
      // else if (role === ROLES.FREE_TEACHER_ACCOUNT) {
      //   dispatch(
      //     setStripeProduct({
      //       id: products[role].id,
      //       price: products[role].price,
      //       postfix: products[role].postfix,
      //     }),
      //   );
      // }
      dispatch(
        stepListActions.nextStep({
          stepListId: STEP_LIST_IDS.signUpStepList,
        }),
      );
    } catch (err) {
      toast.error((err as IAxiosErr).response.data.message);
    }
  },
);

export const createOrganization = createAsyncThunk<
  { id: string; email: string; name: string; max_seats?: number } | void,
  ICreateOrganization,
  { state: IState }
>(
  "organization/createOrganization",
  async ({ email, orgName, max_seats }, { rejectWithValue }) => {
    try {
      const { data } = await organizationApi.createOrganization(
        email,
        orgName,
        max_seats,
      );
      toast.success("Org has been successfully created");
      return data;
    } catch (err) {
      toast.error((err as IAxiosErr).response.data.message);
      return rejectWithValue((err as IAxiosErr).response.data);
    }
  },
);

export const uploadLogo = createAsyncThunk<
  void,
  IUploadLogo,
  { state: IState }
>("organization/uploadLogo", async ({ logo }, { dispatch, getState }) => {
  try {
    const orgId = getOrganizationId(getState());
    if (!orgId) {
      throw orgId;
    }
    const userId = getUserIdSelector(getState());
    if (!userId) {
      throw userId;
    }

    await organizationApi.uploadLogo(logo, orgId);
    dispatch(fetchUserOrganizations({ userId }));
  } catch (e) {
    dispatch(setLogo(""));
  }
});

export const updateLogo = createAsyncThunk<
  void,
  IUpdateLogo,
  { state: IState }
>("organization/updateLogo", async ({ logoPath }, { dispatch, getState }) => {
  dispatch(setLogo(logoPath));
  try {
    const orgId = getOrganizationId(getState());
    if (!orgId) {
      throw orgId;
    }
    await organizationApi.updateLogo(logoPath, orgId);
    toast.success("Logo updated");
  } catch (e) {
    dispatch(setLogo(""));
    toast.error("Can't update logo");
  }
});

export const setLogo = createAction<string>("organization/setLogo");

export interface IChangeGlobalPassword {
  userId: number;
  orgId: number;
  password: string;
}

export const changeGlobalPassword = createAsyncThunk<
  void,
  IChangeGlobalPassword,
  { state: IState }
>(
  "organization/changeGlobalPassword",
  async ({ userId, orgId, password }, { dispatch, getState }) => {
    await organizationApi.changeGlobalPassword(orgId, password);
    dispatch(fetchUserOrganizations({ userId }));
    toast.success("Password Updated");
  },
);

export interface IRemoveStudent {
  studentId: number;
  orgId: number;
  courseId: number;
}

export const removeStudent = createAsyncThunk<
  void,
  IRemoveStudent,
  { state: IState }
>(
  "organization/removeStudent",
  async ({ studentId, orgId, courseId }, { dispatch, getState }) => {
    const userId = getUserIdSelector(getState());
    dispatch(closeModal());
    await organizationApi.removeStudent(studentId, orgId, courseId);
    if (userId) {
      dispatch(fetchUserOrganizations({ userId }));
    }
  },
);

export interface IRemoveExpiredStudents {
  orgId: number;
}

export const removeExpiredStudents = createAsyncThunk<
  void,
  IRemoveExpiredStudents,
  { state: IState }
>(
  "organization/removeExpiredStudents",
  async ({ orgId }, { dispatch, getState }) => {
    await organizationApi.removeExpiredStudents(orgId);
    dispatch(fetchOrganizations());
  },
);

export interface IChangeToggle {
  userId: number;
  orgId: number;
  value: boolean;
}

export const changeGlobalPasswordToggle = createAsyncThunk<
  void,
  IChangeToggle,
  { state: IState }
>(
  "organization/changeGlobalPasswordToggle",
  async ({ userId, orgId, value }, { dispatch }) => {
    await organizationApi.changeGlobalPasswordToggle(orgId, value);
    dispatch(fetchUserOrganizations({ userId }));
  },
);

export const changeRecordToggle = createAsyncThunk<
  void,
  IChangeToggle,
  { state: IState }
>(
  "organization/changeRecordToggle",
  async ({ userId, orgId, value }, { dispatch }) => {
    await organizationApi.changeRecordingsToggle(orgId, value);
    dispatch(fetchUserOrganizations({ userId }));
  },
);

interface ISearchOrganizationStudents {
  orgId: number;
  name: string;
}
export const searchOrganizationStudents = createAsyncThunk<
  IStudentWithSkills[],
  ISearchOrganizationStudents,
  { state: IState }
>("organization/searchOrganizationStudents", async ({ orgId, name }) => {
  const {
    data: { usersWithSkills: students },
  } = await organizationApi.searchStudents(orgId, name);
  return students;
});

export const getHiddenSongs = createAsyncThunk<
  IHiddenSongsApi[],
  void,
  { state: IState }
>("organization/getHiddenSongs", async () => {
  const { data } = await organizationApi.getHiddenSongs();
  return data;
});

export const deleteHiddenSongs = createAsyncThunk<
  void,
  { songId: number },
  { state: IState }
>("organization/deleteHiddenSongs", async ({ songId }) => {
  await organizationApi.deleteHiddenSongs(songId);
});

export const addHiddenSongs = createAsyncThunk<
  void,
  IHiddenSongsApi,
  { state: IState }
>("organization/addHiddenSongs", async ({ id }) => {
  await organizationApi.addHiddenSongs(id);
});

export const searchSongsToHide = createAsyncThunk<
  IHiddenSongsApi[],
  string,
  { state: IState }
>("organization/searchSongsToHide", async (search) => {
  if (search.length < MIN_INPUT_FOR_HELP) {
    return [];
  }
  const { data } = await organizationApi.searchSongByNameToHide(search);
  return data;
});

export const setStaffTabStatusThunk = createAsyncThunk<
  void,
  IStaffTabStatus,
  { state: IState }
>(
  "organization/searchSongsToHide",
  async ({ mode }, { getState, dispatch }) => {
    dispatch(setStaffTabStatus({ mode }));
    const orgId = getOrganizationId(getState());

    const staff = getFirstOrganization(getState())?.staff;
    const tab = getFirstOrganization(getState())?.tab;
    organizationApi.changeTabStaffStatus(orgId, staff, tab);
  },
);

interface IOrganizationState {
  organizations: IOrganization[];
  searchStudents: IStudentWithSkills[];
  hiddenSongs: IHiddenSongs[];
  searchSongsToHide: IHiddenSongsApi[];
}

const initialState: IOrganizationState = {
  organizations: [],
  searchStudents: [],
  hiddenSongs: [],
  searchSongsToHide: [],
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setStaffTabStatus: (state, action: PayloadAction<IStaffTabStatus>) => {
      const { mode } = action.payload;
      if (mode === "staff") {
        state.organizations[0].staff = !state.organizations[0].staff;
      } else {
        state.organizations[0].tab = !state.organizations[0].tab;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchOrganizationStudents.fulfilled, (state, action) => {
      state.searchStudents = action.payload;
    });
    builder.addCase(setUsersOrganizations, (state, action) => {
      state.organizations = action.payload;
    });
    builder.addCase(setLogo, (state, action) => {
      state.organizations[0].logo_url = action.payload;
    });
    builder.addCase(getHiddenSongs.fulfilled, (state, action) => {
      state.hiddenSongs = action.payload.map((song) => ({
        ...song,
        visibility: true,
      }));
    });
    builder.addCase(searchSongsToHide.fulfilled, (state, action) => {
      state.searchSongsToHide = action.payload;
    });
    builder.addCase(deleteHiddenSongs.pending, (state, action) => {
      const { meta } = action;
      state.hiddenSongs.filter((song) => {
        if (song.id === meta.arg.songId) {
          song.visibility = false;
        }
        return song;
      });
    });
    builder.addCase(deleteHiddenSongs.rejected, (state, action) => {
      const { meta } = action;
      state.hiddenSongs.filter((song) => {
        if (song.id === meta.arg.songId) {
          song.visibility = true;
        }
        return song;
      });
    });
    builder.addCase(addHiddenSongs.pending, (state, action) => {
      const {
        meta: {
          arg: { id, artist, song_name },
        },
      } = action;
      state.hiddenSongs.push({ id, artist, song_name, visibility: true });
    });
    builder.addCase(addHiddenSongs.rejected, (state, action) => {
      const { meta } = action;
      state.hiddenSongs.filter((song) => {
        if (song.id === meta.arg.id) {
          song.visibility = false;
        }
        return song;
      });
    });
  },
});

export const {
  reducer: organizationReducer,
  actions: { setStaffTabStatus },
} = organizationSlice;
