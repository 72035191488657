import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { listUsersFromGoogleClassroomCourse } from "redux/import_students/google_classroom/google_classroom_students_slice";
import { listUsersFromGroup } from "redux/import_students/microsoft_teams/microsoft_teams_students_slice";
import css from "./course_card.module.scss";
import { GoogleClassroomSteps } from "../add_from_google_classroom/add_from_google_classroom";
import { ExternalResource } from "../add_from_external_resources";

interface ICourseCard {
  type: ExternalResource;
  courseName: string;
  courseId: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  userToken?: string;
}

export const CourseCard = ({
  type,
  courseName,
  courseId,
  setCurrentStep,
  userToken,
}: ICourseCard) => {
  const dispatch = useDispatch();

  const courseClickHandler = async (e: React.MouseEvent<HTMLDivElement>) => {
    if (type === ExternalResource.GOOGLE_CLASSROOM) {
      dispatch(
        listUsersFromGoogleClassroomCourse({ courseId, token: userToken }),
      );
    }
    if (type === ExternalResource.MS_TEAMS) {
      dispatch(listUsersFromGroup({ groupId: courseId }));
    }

    setCurrentStep(GoogleClassroomSteps.STUDENTS_LIST);
  };

  return (
    <Box className={css.wrapper} onClick={courseClickHandler}>
      <p className={css.text}>{courseName}</p>
    </Box>
  );
};
