import React from "react";
import { useDispatch } from "react-redux";
import * as msal from "@azure/msal-browser";
import { oauthLogin } from "redux/auth/auth_slice";

interface ISSHLogIn {
  children: React.ReactNode;
  provider: "Google" | "Microsoft";
  // eslint-disable-next-line
  additionalAction?: ({ email }: { email?: string }) => void;
}

const msalConfig = {
  auth: {
    clientId: "15ed0bd4-4290-42a8-aceb-76bbecdd77d3",
  },
};

const loginRequest = {
  scopes: ["user.read", "openid", "profile", "email"], // optional Array<string>
};
const iOSApp = navigator.userAgent.includes("iOSMoosikoMobileApp");
export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const SSHLogIn = ({
  children,
  provider,
  additionalAction,
}: ISSHLogIn) => {
  const dispatch = useDispatch();

  const handleGoogleLogIn = async () => {
    //  handle iOS authentication, this event will be handled by the iOS app
    if (iOSApp) {
      await (window as any).webkit.messageHandlers.socialAuth.postMessage(
        "google",
      );
    }
  };

  const handleMicrosoftLogIn = async () => {
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      const { accessToken } = loginResponse;
      await dispatch(oauthLogin({ token: accessToken, provider: "microsoft" }));
      if (additionalAction) {
        additionalAction({ email: loginResponse.account?.username });
      }
    } catch (err) {
      console.error(err);
    }
  };

  let onClick;
  if (provider === "Google") {
    onClick = handleGoogleLogIn;
  } else if (provider === "Microsoft") {
    onClick = handleMicrosoftLogIn;
  }

  return (
    <button type="button" onClick={onClick}>
      {children}
    </button>
  );
};
