/* eslint-disable no-magic-numbers */

import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
} from "@material-ui/core";
import { SideBar } from "components/sidebar/sidebar";
import useMicrophone from "hooks/use_essentia";
import { useState } from "react";

let chordCount = 0;
const decimalPlaces = 4;
const histogramDataDefault = [
  "A",
  "A#",
  "B",
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
].map((note) => ({
  note,
  LT0: 0,
  LT1: 0,
  LT2: 0,
  LT3: 0,
  LT4: 0,
  LT5: 0,
  LT6: 0,
  LT7: 0,
  LT8: 0,
  LT9: 0,
  LT10: 0,
}));

let histogramData = JSON.parse(JSON.stringify(histogramDataDefault));

export const ChordTrainer = () => {
  const [noteData, setNoteData] = useState([
    { note: "A", min: 0, max: 1, check: false },
    { note: "A#", min: 0, max: 1, check: false },
    { note: "B", min: 0.2, max: 1, check: true },
    { note: "C", min: 0, max: 1, check: false },
    { note: "C#", min: 0, max: 1, check: false },
    { note: "D", min: 0, max: 1, check: false },
    { note: "D#", min: 0, max: 1, check: false },
    { note: "E", min: 0.2, max: 1, check: true },
    { note: "F", min: 0, max: 1, check: false },
    { note: "F#", min: 0, max: 1, check: false },
    { note: "G", min: 0, max: 1, check: false },
    { note: "G#", min: 0.2, max: 1, check: true },
  ]);

  const {
    isMicrophoneOn,
    startMicrophone,
    stopMicrophone,
    rmsValue,
    hpcpData,
    // TODO: Rename this function to something more descriptive
  } = useMicrophone();

  const toggleEssentia = () => {
    if (isMicrophoneOn) {
      stopMicrophone();
    } else {
      // eslint-disable-next-line no-magic-numbers
      startMicrophone(() => chordCount++, 0.05, noteData);
      chordCount = 0;
      histogramData = JSON.parse(JSON.stringify(histogramDataDefault));
    }
  };

  const getBackgroundColor = (value: number) => {
    if (value < 5) {
      return "hsl(240, 100%, 95%)";
    }
    if (value < 10) {
      return "hsl(240, 100%, 85%)";
    }
    if (value < 15) {
      return "hsl(240, 100%, 75%)";
    }
    if (value < 20) {
      return "hsl(240, 100%, 65%)";
    }
    if (value < 25) {
      return "hsl(240, 100%, 55%)";
    }
    if (value < 30) {
      return "hsl(240, 100%, 45%)";
    }
    if (value < 35) {
      return "hsl(240, 100%, 35%)";
    }
    if (value < 40) {
      return "hsl(240, 100%, 25%)";
    }
    if (value < 45) {
      return "hsl(240, 100%, 15%)";
    }
    if (value < 50) {
      return "hsl(240, 100%, 10%)";
    }
    return "hsl(240, 100%, 5%)";
  };

  return (
    <Box display="flex" style={{ paddingTop: "32px" }}>
      <SideBar />
      <Box>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            lineHeight: "35px",
            color: "#190067",
            marginBottom: "60px",
            fontFamily: "Raleway",
          }}
        >
          Chord Trainer
        </Typography>

        <Button onClick={toggleEssentia} variant="outlined">
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              lineHeight: "23px",
              color: "#190067",
              fontFamily: "Raleway",
            }}
          >
            {isMicrophoneOn ? "Stop" : "Start"} Chord Trainer
          </Typography>
        </Button>

        <Typography>
          RMS Value {rmsValue ? rmsValue.toFixed(decimalPlaces) : 0}
        </Typography>
        <Typography>Chord Count {chordCount}</Typography>
        {/* <Box display="grid" gridTemplateColumns="repeat(6, 1fr)"> */}
        <Box display="flex" flexDirection="row">
          <Typography style={{ width: "100px" }}>Enabled</Typography>
          <Typography style={{ width: "100px" }}>Minimum</Typography>
          <Typography style={{ width: "100px" }}>Maximum</Typography>
          <Typography style={{ width: "50px" }}>Note</Typography>

          <Typography style={{ width: "50px" }}>0.0</Typography>
          <Typography style={{ width: "50px" }}>0.1</Typography>
          <Typography style={{ width: "50px" }}>0.2</Typography>
          <Typography style={{ width: "50px" }}>0.3</Typography>
          <Typography style={{ width: "50px" }}>0.4</Typography>
          <Typography style={{ width: "50px" }}>0.5</Typography>
          <Typography style={{ width: "50px" }}>0.6</Typography>
          <Typography style={{ width: "50px" }}>0.7</Typography>
          <Typography style={{ width: "50px" }}>0.8</Typography>
          <Typography style={{ width: "50px" }}>0.9</Typography>
          <Typography style={{ width: "50px" }}>1.0</Typography>
        </Box>
        {noteData.map((note, index) => {
          if (rmsValue > 0.05) {
            const hpcpValue = hpcpData[index];
            const histogramIndex = Math.min(Math.floor(hpcpValue * 10), 10);
            histogramData[index][`LT${histogramIndex}`] += 1;
          }

          return (
            <Box key={note.note} display="flex" flexDirection="row">
              <Checkbox
                style={{ width: "100px", padding: "0px" }}
                checked={note.check}
                onClick={() => {
                  const newNoteData = [...noteData];
                  newNoteData[index] = {
                    ...newNoteData[index],
                    check: !newNoteData[index].check,
                  };
                  setNoteData(newNoteData);
                }}
              />
              <TextField
                style={{ width: "100px" }}
                type="number"
                value={note.min}
                onChange={(e) => {
                  const newNoteData = [...noteData];
                  newNoteData[index] = {
                    ...newNoteData[index],
                    min: Number(e.target.value),
                  };
                  setNoteData(newNoteData);
                }}
                inputProps={{
                  step: "0.1",
                  min: "0",
                  max: "1",
                }}
              />
              <TextField
                style={{ width: "100px" }}
                type="number"
                value={note.max}
                onChange={(e) => {
                  const newNoteData = [...noteData];
                  newNoteData[index] = {
                    ...newNoteData[index],
                    max: Number(e.target.value),
                  };
                  setNoteData(newNoteData);
                }}
                inputProps={{
                  step: "0.1",
                  min: "0",
                  max: "1",
                }}
              />
              <Typography style={{ width: "50px" }}>{note.note}</Typography>
              {Array.from({ length: 11 }, (_, i) => (
                <Typography
                  key={i}
                  style={{
                    width: "50px",
                    background: getBackgroundColor(
                      histogramData[index][`LT${i}`],
                    ),
                    color:
                      histogramData[index][`LT${i}`] >= 30 ? "white" : "black",
                  }}
                >
                  {histogramData[index][`LT${i}`]}
                </Typography>
              ))}
            </Box>
          );
        })}

        {/* </Box> */}
      </Box>
    </Box>
  );
};
