import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "redux/auth/user_selectors";
import { setPermissions } from "redux/auth/auth_slice";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";

import { Redirect, Route, Switch } from "react-router-dom";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import { ProfileLayout } from "components/layouts/profile";
import { ResetPasswordRoute } from "routes/reset_password_route";
import { WithAccessRoute } from "routes/with_access_route";
import { LessonRoute } from "routes/lesson_rouute";
import { ProtectedRoute } from "routes/protected_route";
import { AuthRoute } from "routes/auth_route";
import {
  ALL_SONGS,
  PROFILE_ROUTE,
  ORG_MANAGEMENT,
  ASSESSMENT,
  LESSON,
  LOGIN,
  MY_JOURNEY,
  MY_SONGS,
  ORGANIZATION,
  VIDEO,
  REQ_PASSWORD_RESET,
  RESET_PASSWORD,
  MODAL_SIGNUP,
  SONG_SHEET,
  COURSE,
  TUNER,
  STUDENT_INFO,
  SETTINGS,
  SETTINGS_HIDDEN_SONGS,
  SONGWRITING,
  SONGWRITING_ASSESSMENT,
  SONGWRITING_SONG_IDEAS,
  SONGWRITING_LYRIC_IDEAS,
  SONGWRITING_AUDIO_IDEAS,
  MOBILE_SIGNUP,
  CHORD_TRAINER,
} from "../routes/route_constants";
import { Course } from "./course";
import { CreateOrg } from "./create_org";
import { MyJourney } from "./my_journey/my_journey";
import { MySongsLayout } from "./songs/my_songs_layout";
import Nav from "./nav/nav";
import { Slides } from "./slides/slides";
import { SongList } from "./songs/song_list";
import { SongSheetPage } from "./song_sheet/SongSheetPage";
import "react-toastify/dist/ReactToastify.css";
import { Organization } from "./organization";
import { OrgManagement } from "./org_management/org_management";
import { Assessment } from "./assessment";
import { SongwritingAssessment } from "./songwriting_assessment";
import { VideoPage } from "./video_page";
import { StudentInfo } from "./course/student_info";
import { StripeCheckout } from "./stripe/stripe_checkout/stripe_checkout";
import { Settings } from "./settings/settings";
import { TunerLayout } from "./tuner_layout/tuner_layout";
import { SongWriting } from "./songwriting";
import { MobileSongWriting } from "./songwriting_mobile";
import { SongIdeas } from "./songwriting_mobile/song_ideas";
import { LyricIdeas } from "./songwriting_mobile/lyric_ideas";
import { AudioIdeas } from "./songwriting_mobile/audio_ideas";
import { ChordTrainer } from "./admin/chord_trainer";

export const MainContent = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getUserSelector);
  const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);

  if (!loggedIn) {
    dispatch(
      setPermissions(
        mobile
          ? [PERMISSIONS.SONGWRITING]
          : [
              PERMISSIONS.ACCESS_TO_GUITAR,
              PERMISSIONS.ACCESS_TO_UKULELE,
              PERMISSIONS.SONGWRITING,
            ],
      ),
    );
  }

  const accessGuitar = useSelector(
    accessByPermissionSelector(PERMISSIONS.ACCESS_TO_GUITAR),
  );

  const songwritingDefaultPage = mobile ? MobileSongWriting : SongWriting;
  const appBackgroundColor = mobile ? "#f2f1f6" : "#fff";

  return mobile && !loggedIn ? (
    <>
      <Nav />
      <main
        id="app_main"
        className="app_main"
        style={{ backgroundColor: appBackgroundColor }}
      >
        <Switch>
          <AuthRoute path={LOGIN} />
          <AuthRoute path={MOBILE_SIGNUP} />
          <Route exact path="/newMobileUser" component={MobileSongWriting} />
          <ResetPasswordRoute path={RESET_PASSWORD} />
          <Redirect to={MOBILE_SIGNUP} />
        </Switch>
      </main>
    </>
  ) : (
    <>
      <Nav />
      <main
        id="app_main"
        className="app_main"
        style={{ backgroundColor: appBackgroundColor }}
      >
        <Switch>
          <AuthRoute path={MODAL_SIGNUP} />
          <AuthRoute path={LOGIN} />
          <AuthRoute path={REQ_PASSWORD_RESET} />
          <ProtectedRoute
            path={MY_SONGS}
            component={MySongsLayout}
            permission={PERMISSIONS.ACCESS_TO_GUITAR}
          />
          <ProtectedRoute
            path={MY_JOURNEY}
            component={MyJourney}
            permission={PERMISSIONS.ACCESS_TO_GUITAR}
          />
          <ProtectedRoute
            path={SONGWRITING}
            component={mobile ? songwritingDefaultPage : SongWriting}
            permission={PERMISSIONS.SONGWRITING}
          />
          <ProtectedRoute path={TUNER} component={TunerLayout} />
          <ProtectedRoute path={PROFILE_ROUTE} component={ProfileLayout} />
          <ProtectedRoute path={VIDEO} component={VideoPage} />
          <ProtectedRoute path={SONGWRITING_SONG_IDEAS} component={SongIdeas} />
          <ProtectedRoute
            path={SONGWRITING_LYRIC_IDEAS}
            component={LyricIdeas}
          />
          <ProtectedRoute
            path={SONGWRITING_AUDIO_IDEAS}
            component={AudioIdeas}
          />

          <WithAccessRoute
            path={ORG_MANAGEMENT}
            component={OrgManagement}
            permission={PERMISSIONS.MANAGE_ORGANIZATIONS}
          />
          <WithAccessRoute
            path={ASSESSMENT}
            component={Assessment}
            permission={PERMISSIONS.STUDENT_VIDEO_RECORDING}
          />
          <WithAccessRoute
            path={SONGWRITING_ASSESSMENT}
            component={SongwritingAssessment}
            permission={PERMISSIONS.STUDENT_ACCESS}
          />
          <WithAccessRoute
            path={COURSE}
            component={Course}
            permission={PERMISSIONS.STUDENT_ACCESS}
          />
          <WithAccessRoute
            path={ORGANIZATION}
            component={Organization}
            permission={PERMISSIONS.STUDENT_ACCESS}
          />
          <WithAccessRoute
            exact
            path={SETTINGS}
            component={() => Settings({ page: "main" })}
            permission={PERMISSIONS.STUDENT_ACCESS}
          />
          <WithAccessRoute
            exact
            path={SETTINGS_HIDDEN_SONGS}
            component={() => Settings({ page: "hiddenSongs" })}
            permission={PERMISSIONS.STUDENT_ACCESS}
          />
          <WithAccessRoute
            path="/create"
            component={CreateOrg}
            permission={PERMISSIONS.CREATE_ORGANIZATIONS}
          />

          <WithAccessRoute
            path={ALL_SONGS}
            component={SongList}
            permission={PERMISSIONS.ACCESS_TO_GUITAR}
          />
          <WithAccessRoute
            path={CHORD_TRAINER}
            component={ChordTrainer}
            // to do: Create an administrative permission for this
            permission={PERMISSIONS.SONGWRITING}
          />

          <LessonRoute path={SONG_SHEET} component={SongSheetPage} />
          <LessonRoute path={LESSON} component={Slides} />
          <ResetPasswordRoute path={RESET_PASSWORD} />
          <WithAccessRoute
            path={STUDENT_INFO}
            component={StudentInfo}
            permission={PERMISSIONS.STUDENT_ACCESS}
          />
          <Route path="/checkout" component={StripeCheckout} />
          <Route
            exact
            path="/"
            component={accessGuitar ? SongList : songwritingDefaultPage}
          />
          <Redirect to="/" />
        </Switch>
      </main>
    </>
  );
};
