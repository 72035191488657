import {
  IClassroomStudent,
  IClassroomCourse,
} from "types/helpers/classroomTypes";

export function handleClassroomAuthClick() {
  console.error("call for retired auth code");
}

export async function listCoursesHelper(
  token?: string,
  pageSize = 10,
): Promise<IClassroomCourse[] | []> {
  const response = await fetch(
    `https://classroom.googleapis.com/v1/courses?pageSize=${pageSize}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch courses");
  }

  const data = await response.json();
  return data.courses;
}

export async function listUsersFromChosenCourseHelper(
  courseId: string,
  token?: string,
): Promise<IClassroomStudent[] | []> {
  const response = await fetch(
    `https://classroom.googleapis.com/v1/courses/${courseId}/students`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch students");
  }

  const data = await response.json();

  return data.students;
}
