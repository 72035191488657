/* eslint-disable no-unused-vars */
import { ChangeEvent, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveCourses,
  getExpiredCourses,
} from "redux/courses/courses_selectors";
import { getFirstOrganization } from "redux/organizations/organization_selectors";
import { getUserSelector } from "redux/auth/user_selectors";
import { fetchMediaRecordings } from "redux/media_recordings/media_recordings_slice";
import { fetchOrgSongs } from "redux/songwriting/songwriting_slice";
import { getNewMediaRecordingsCount } from "redux/media_recordings/media_recordings_selectors";
import { ICourse } from "redux/courses/courses_api";
import { ORGANIZATION, PROFILE_ROUTE, SETTINGS } from "routes/route_constants";
import { accessByPermissionSelector } from "redux/auth/permissions/permissions_selector";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import css from "./sidebar.module.scss";

enum AccordionTabs {
  ACTIVE_COURSE_TAB = "activeCourse",
  EXPIRED_COURSE_TAB = "expiredCourse",
}

export const SideBar = () => {
  const accessStudent = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_ACCESS),
  );
  const accessVideoRecording = useSelector(
    accessByPermissionSelector(PERMISSIONS.STUDENT_VIDEO_RECORDING),
  );
  const accessUnlimitedStudents = useSelector(
    accessByPermissionSelector(PERMISSIONS.ADD_UNLIMITED_STUDENTS),
  );
  const [expanded, setExpanded] = useState<string | false>(
    AccordionTabs.ACTIVE_COURSE_TAB,
  );

  const org = useSelector(getFirstOrganization);
  const dispatch = useDispatch();

  const activeCourses: ICourse[] = useSelector(getActiveCourses);
  const expiredCourses: ICourse[] = useSelector(getExpiredCourses);
  const user = useSelector(getUserSelector);
  const courseId = Number(useParams<{ courseId: string }>().courseId);

  const chosenCourse = useRef<number | boolean>(false);

  useEffect(() => {
    chosenCourse.current = courseId;
  }, [courseId]);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (accessStudent && org?.id) {
      dispatch(fetchMediaRecordings({ orgId: org.id }));
    }
  }, [dispatch, org, accessStudent]);

  useEffect(() => {
    if (accessStudent && org?.id && user?.id) {
      dispatch(fetchOrgSongs({ orgId: org.id, excludeUserId: user.id }));
    }
  }, [dispatch, org, user, accessStudent]);

  useEffect(() => {
    const chosenExpired =
      expiredCourses.find((elem) => elem.id === chosenCourse.current) &&
      AccordionTabs.EXPIRED_COURSE_TAB;

    setExpanded(chosenExpired || AccordionTabs.ACTIVE_COURSE_TAB);
  }, [chosenCourse, expiredCourses]);

  const newAssessmentsCount = useSelector(getNewMediaRecordingsCount);

  const { pathname } = useLocation();
  return (
    <aside className={css.sidebar}>
      <div className={css.scrollable_sidebar}>
        <div className={css.sidebar_tabs}>
          <Link
            className={classnames(
              css.sidebar_tab,
              pathname === PROFILE_ROUTE ? css.link_active : null,
            )}
            to={PROFILE_ROUTE}
          >
            Profile
          </Link>
          {accessStudent ? (
            <>
              {accessUnlimitedStudents && (
                <>
                  <Link
                    className={classnames(
                      css.sidebar_tab,
                      pathname === SETTINGS ? css.link_active : null,
                    )}
                    to={SETTINGS}
                  >
                    Settings
                  </Link>
                  <Link
                    className={classnames(
                      css.sidebar_tab,
                      pathname === ORGANIZATION ? css.link_active : null,
                    )}
                    to={ORGANIZATION}
                  >
                    Classes
                  </Link>
                  <Accordion
                    classes={{ root: css.accordion }}
                    elevation={0}
                    expanded={expanded === AccordionTabs.ACTIVE_COURSE_TAB}
                    onChange={handleChange(AccordionTabs.ACTIVE_COURSE_TAB)}
                  >
                    <AccordionSummary
                      classes={{
                        root: css.accordion_summary,
                        expanded: css.expanded_icon,
                      }}
                      expandIcon={
                        <ArrowForwardIos classes={{ root: css.arrow_icon }} />
                      }
                    >
                      <p
                        className={classnames(css.accordion_title, {
                          [css.accordion_title_active]:
                            expanded === AccordionTabs.ACTIVE_COURSE_TAB,
                          [css.accordion_title_disabled]:
                            expanded === AccordionTabs.EXPIRED_COURSE_TAB ||
                            expanded === false,
                        })}
                      >
                        Active ({activeCourses.length})
                      </p>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: css.accordion_details }}>
                      <ul className={css.courses_links}>
                        {activeCourses.map((course) => (
                          <li
                            key={`${course.name} ${course.createdAt}`}
                            className={classnames(css.course_nav_link)}
                          >
                            <Link
                              className={classnames(
                                css.course_nav_link,
                                courseId === course.id
                                  ? css.course_nav_link_active
                                  : null,
                              )}
                              to={`/organization/${course.id}`}
                            >
                              {course.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    classes={{ root: css.accordion }}
                    elevation={0}
                    expanded={expanded === AccordionTabs.EXPIRED_COURSE_TAB}
                    onChange={handleChange(AccordionTabs.EXPIRED_COURSE_TAB)}
                  >
                    <AccordionSummary
                      classes={{
                        root: css.accordion_summary,
                        expanded: css.expanded_icon,
                      }}
                      expandIcon={
                        <ArrowForwardIos classes={{ root: css.arrow_icon }} />
                      }
                    >
                      <p
                        className={classnames(css.accordion_title, {
                          [css.accordion_title_disabled]:
                            expanded === AccordionTabs.ACTIVE_COURSE_TAB ||
                            expanded === false,
                          [css.accordion_title_active]:
                            expanded === AccordionTabs.EXPIRED_COURSE_TAB,
                        })}
                      >
                        Expired ({expiredCourses.length})
                      </p>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: css.accordion_details }}>
                      <ul className={css.courses_links}>
                        {expiredCourses.map((course) => (
                          <li
                            key={`${course.name} ${course.createdAt}`}
                            className={classnames(css.course_nav_link)}
                          >
                            <Link
                              className={classnames(
                                css.course_nav_link,
                                courseId === course.id
                                  ? css.course_nav_link_active
                                  : null,
                              )}
                              to={`/organization/${course.id}`}
                            >
                              {course.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>{" "}
                </>
              )}
              {accessVideoRecording && (
                <Link
                  className={classnames(css.sidebar_tab, css.assessment_link, {
                    [css.link_active]: pathname === "/organization/assessment",
                  })}
                  to="/organization/assessment"
                >
                  Assessments
                  {newAssessmentsCount ? (
                    <div className={css.new_assessments}>
                      {newAssessmentsCount}
                    </div>
                  ) : null}
                </Link>
              )}
              {accessStudent && (
                <Link
                  className={classnames(css.sidebar_tab, css.assessment_link, {
                    [css.link_active]:
                      pathname === "/organization/songwriting-assessment",
                  })}
                  to="/organization/songwriting-assessment"
                >
                  Songwriting
                </Link>
              )}
              {/* TODO: Put this behind an access setting */}
              {process.env.REACT_APP_NODE_ENV !== "production" && (
                <Link
                  className={classnames(css.sidebar_tab)}
                  to="/chord-trainer"
                >
                  Chord Trainer
                </Link>
              )}
            </>
          ) : null}
        </div>
      </div>
    </aside>
  );
};
