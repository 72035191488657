import React from "react";
import { Box } from "@material-ui/core";
import { AvailableProvidersEnum } from "../login_modal";
import { ICONS } from "./icons";

import css from "./button_auth.module.scss";

interface IButtonAuth {
  provider: AvailableProvidersEnum;
  handleClick?: () => void;
  overideLabel?: string;
}

export const ButtonAuth = ({
  provider,
  handleClick,
  overideLabel,
}: IButtonAuth) => {
  const buttonLabel = () => {
    if (overideLabel) {
      return `${overideLabel} ${provider}`;
    }
    return `Sign In with ${provider}`;
  };

  return (
    <Box className={css.button_auth} onClick={handleClick}>
      <div>
        <svg
          width={provider === AvailableProvidersEnum.email ? "27" : "20"}
          height={provider === AvailableProvidersEnum.email ? "27" : "20"}
          viewBox={
            provider === AvailableProvidersEnum.email
              ? "0 0 36 30"
              : "0 0 20 20"
          }
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {ICONS[provider]}
        </svg>
      </div>
      <Box style={{ width: "100%", textAlign: "center" }}>{buttonLabel()}</Box>
    </Box>
  );
};
