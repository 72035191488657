/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listGoogleClassroomCourses } from "redux/import_students/google_classroom/google_classroom_students_slice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { stepListActions } from "redux/step_list/stepListSlice";
import { AppDispatch } from "types/redux_";
import { useGoogleLogin } from "@react-oauth/google";
import { SENSITIVE_SCOPES } from "constants/keys";

import { ExternalResource } from "components/organization/add_from_external_resources/add_from_external_resources";
import { stepsId } from "../../../newSignUp";
import { TopLineProgress } from "../../../reusable/top_line_progress";
import { StretchContainer, StyledButtonBack, Top } from "../../emotion.styles";
import { BulkImportClassroomList } from "../bulk_import_classroom_list/bulk_import_classroom_list";
import { StyledBackIcon } from "./bulk_import_from_google_classroom.styled";
import { BulkImportStudentList } from "../bulk_import_student_list/bulk_import_student_list";

export enum STEPS {
  CLASSROOM_LIST = "CLASSROOM_LIST",
  STUDENT_LIST = "STUDENT_LIST",
}

export const BulkImportFromGoogleClassroom = () => {
  const [step, setStep] = useState<STEPS>(STEPS.CLASSROOM_LIST);

  const dispatch = useDispatch<AppDispatch>();

  const handleBackStep = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.addStudents,
      }),
    );
  };

  const [userToken, setUserToken] = useState<string>();

  useEffect(() => {
    if (userToken) {
      dispatch(listGoogleClassroomCourses({ token: userToken }));
    }
  }, [dispatch, userToken]);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setUserToken(tokenResponse.access_token);
    },
    scope: SENSITIVE_SCOPES,
  });

  useEffect(() => {
    login();
  }, [login]);

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={90} />
        <StyledButtonBack type="button" onClick={handleBackStep}>
          <StyledBackIcon />
        </StyledButtonBack>
      </Top>
      {step === STEPS.CLASSROOM_LIST ? (
        <BulkImportClassroomList
          type={ExternalResource.GOOGLE_CLASSROOM}
          setCurrentStep={setStep}
          userToken={userToken}
        />
      ) : (
        <BulkImportStudentList type={ExternalResource.GOOGLE_CLASSROOM} />
      )}
    </StretchContainer>
  );
};
