export const LOGIN = "/login";
export const MODAL_SIGNUP = "/modal-signup";
export const MOBILE_SIGNUP = "/mobile-signup";
export const MY_SONGS = "/my-songs";
export const ALL_SONGS = "/all-songs";
export const SONG_SHEET = "/lesson/:id/song-sheet";
export const LESSON = "/lesson/:id";
export const TUNER = "/tuner";
export const RESET_PASSWORD = "/reset-password";
export const REQ_PASSWORD_RESET = "/req-password-reset";
export const PROFILE_ROUTE = "/profile";
export const SETTINGS = "/settings";
export const SETTINGS_HIDDEN_SONGS = "/settings/hiddenSongs";
export const MY_JOURNEY = "/my-journey";
export const COURSE = "/organization/:courseId";
export const ORGANIZATION = "/organization";
export const ORG_MANAGEMENT = "/org-management";
export const ASSESSMENT = "/organization/assessment";
export const SONGWRITING_ASSESSMENT = "/organization/songwriting-assessment";
export const VIDEO = "/video/:id";
export const STUDENT_INFO = "/student/:courseId/:userId";
export const SIGNUP = "/signup";
export const UPGRADE = "/upgrade";
export const SONGWRITING = "/songwriting";
export const SONGWRITING_SONG_IDEAS = "/songwriting-song-ideas";
export const SONGWRITING_LYRIC_IDEAS = "/songwriting-lyric-ideas";
export const SONGWRITING_AUDIO_IDEAS = "/songwriting-audio-ideas";
export const CHORD_TRAINER = "/chord-trainer";
export const MAIN = "/";
