import { useCallback, useEffect } from "react";
import {
  getUserEmailSelector,
  getUserProviderSelector,
} from "redux/auth/user_selectors";
import { useDispatch, useSelector } from "react-redux";
import { SignUpForm } from "components/modal/signup_modal/signup_form";
import { oauthLogin, updateUserRole } from "redux/auth/auth_slice";
import { ROLES } from "redux/auth/roles/roles_consts";
import { getSignUpStepsUserDataSelector } from "redux/sign_up_steps/sign_up_steps_selectors";
import { stepListActions } from "redux/step_list/stepListSlice";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { MicrosoftButton } from "components/reusable/buttons/MicrosoftButton";
import { GoogleLogin } from "@react-oauth/google";
import { Header, StretchContainer, Top } from "../emotion.styles";
import { FooterSignUp } from "../../reusable/footer_sign_up";
import { TopLineProgress } from "../../reusable/top_line_progress";
import { LogoWithBackground } from "../../reusable/background_image";
import { CloseStepsButton } from "../../reusable/close_steps_button";
import { SSHLogIn } from "../../reusable/SSHLogIn";
import { stepsId } from "../../newSignUp";
import {
  StyledContainer,
  FormContainer,
  LogoContainer,
  EmailDescription,
} from "./create_account.styled";

import defaultAvatar from "../../images/default-avatar.svg";

export const CreateTeacherAccount = () => {
  const dispatch = useDispatch();
  const role =
    useSelector(getSignUpStepsUserDataSelector)?.role ||
    ROLES.FREE_TEACHER_ACCOUNT;

  const nextStepSSHsignUp = useCallback(
    async ({ email }: { email?: string }) => {
      if (email) {
        dispatch(updateUserRole({ email, roleName: role }));
        dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.signUpStepList,
            stepId: stepsId.createOrg,
          }),
        );
      }
    },
    [dispatch, role],
  );

  const userEmail = useSelector(getUserEmailSelector);
  const userProvider = useSelector(getUserProviderSelector);

  const googleSuccess = async (response: any) => {
    dispatch(oauthLogin({ token: response.credential, provider: "google" }));
  };

  useEffect(() => {
    if (userProvider === "google_oauth2") {
      userEmail && nextStepSSHsignUp({ email: userEmail });
    }
  }, [userEmail, userProvider, nextStepSSHsignUp]);

  return (
    <StretchContainer>
      <Top>
        <TopLineProgress percent={18} />
        <CloseStepsButton />
      </Top>
      <StyledContainer>
        <Header>Create Account</Header>
        <LogoWithBackground
          src={defaultAvatar}
          padding="33px"
          margin="1em 1em 1.5em"
        />
        Single Sign On (SSO) using your school email is preferred in order to
        comply with FERPA and district IT policies.
        <LogoContainer>
          <SSHLogIn provider="Google">
            <GoogleLogin onSuccess={googleSuccess} width="400" />
          </SSHLogIn>

          <SSHLogIn provider="Microsoft" additionalAction={nextStepSSHsignUp}>
            <MicrosoftButton />
          </SSHLogIn>
        </LogoContainer>
        <EmailDescription>
          {"OR\n\nEnter Your School Email Address"}
        </EmailDescription>
        Using your school email address, instead of a personal one, will make it
        easier to add your students later.
        <FormContainer>
          <SignUpForm role={role} />
        </FormContainer>
      </StyledContainer>
      <FooterSignUp
        textButton="Create Account"
        type="submit"
        form="signUp_default_form"
      >
        By creating an account you agree to our Terms of Use
      </FooterSignUp>
    </StretchContainer>
  );
};
