import classnames from "classnames";
import { IStripeProduct } from "components/stripe/stripe_checkout/types";
import { useSelector, useDispatch } from "react-redux";
import { getUserEmailSelector } from "redux/auth/user_selectors";
import { useEffect } from "react";
import { setStripeProduct } from "redux/entities/stripe/stripe_slice";

import { stripeApi } from "api/stripe_api";

import css from "./subscription_plan.module.scss";

interface IMemberPlanPrice {
  product: IStripeProduct;
}

export const MemberPlanPrice = (props: IMemberPlanPrice) => {
  const dispatch = useDispatch();
  const email = useSelector(getUserEmailSelector) as string;
  useEffect(() => {
    const fetchUserData = async () => {
      const fetchResult = await stripeApi.getCustomerData(email);
      if (fetchResult.knownStripeUser) {
        dispatch(
          setStripeProduct({
            id: "",
            price: fetchResult.price,
            postfix: fetchResult.postfix,
          }),
        );
      }
    };
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { product } = props;

  return (
    <div className={css.price_block_wrapper}>
      <div className={classnames(css.price_block, css.member_plan)}>
        <div className={css.currency}>$</div>
        <div className={css.price}>{product.price}</div>
        <div className={css.length}>/{product.postfix}</div>
      </div>
    </div>
  );
};
