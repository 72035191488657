import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserFeedback } from "redux/feedback/feedback_slice";
import { getUserIdSelector } from "redux/auth/user_selectors";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IMediaRecord } from "redux/media_recordings/media_recordings_api";
import css from "./feedback.module.scss";
import { FeedBackHeader } from "./feedback_header";
import { FeedBackRow } from "./feedback_row/feedback_row";

interface IFeedback {
  mediaRecordsWithFeedbacks: IMediaRecord[];
}

export const Feedback = ({ mediaRecordsWithFeedbacks }: IFeedback) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserIdSelector);

  useEffect(() => {
    dispatch(fetchUserFeedback({ studentId: Number(userId) }));
  }, [dispatch, userId]);

  const [pagination, setPagination] = useState(0);

  const limit = 10;
  const finalRecords = mediaRecordsWithFeedbacks.slice(
    pagination,
    pagination + limit,
  );

  if (!finalRecords.length) {
    return null;
  }

  const movePagination = (value: number) => {
    let newPagination = pagination + value;

    if (newPagination < 0) {
      newPagination = 0;
    }
    if (newPagination > mediaRecordsWithFeedbacks.length) {
      newPagination = pagination;
    }
    setPagination(newPagination);
  };

  const start = pagination + 1;
  const end =
    pagination + limit + 1 > mediaRecordsWithFeedbacks.length
      ? mediaRecordsWithFeedbacks.length
      : pagination + limit;
  return (
    <div className={css.container}>
      <header className={css.header}>Videos</header>
      <FeedBackHeader />
      {finalRecords.map((mediaRecordWithFeedback) => (
        <FeedBackRow
          key={mediaRecordWithFeedback.id}
          mediaRecordWithFeedback={mediaRecordWithFeedback}
        />
      ))}
      {mediaRecordsWithFeedbacks.length > limit ? (
        <div className={css.pagination}>
          <button
            className={css.pagination_button}
            type="button"
            onClick={() => movePagination(-limit)}
          >
            <ArrowBackIosIcon />
          </button>
          <div className={css.pagination_text}>
            Showing {start} - {end} of {mediaRecordsWithFeedbacks.length}
          </div>
          <button
            className={css.pagination_button}
            type="button"
            onClick={() => movePagination(limit)}
          >
            <ArrowForwardIosIcon />
          </button>
        </div>
      ) : null}
    </div>
  );
};
