import { isActionRejectedHelper } from "helpers/is_action_rejected_helper";
import { userApi } from "api/user_api";
import { STEP_LIST_IDS } from "redux/step_list/stepListConstants";
import { stepListActions } from "redux/step_list/stepListSlice";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLocation, push } from "connected-react-router";
import { fetchUserFeedback } from "redux/feedback/feedback_slice";
import { fetchUserMediaRecordings } from "redux/media_recordings/media_recordings_slice";
import { fetchUserOrganizations } from "redux/organizations/organization_courses_common_actions";
import { fetchUserCourses } from "redux/user_courses/user_courses_slice";
import { IUserEmail, userEmailApi } from "redux/user_email/user_email_api";
import { IState, store } from "redux/store";
import { Stripe } from "stripe";
import { analyticUtil } from "util/analytic";
import { timer } from "util/user_session_timer";
import { DEV } from "util/vars";
import mixpanel from "mixpanel-browser";
import { setUserDataSignup } from "redux/sign_up_steps/sign_up_steps_slice";
import storage from "redux-persist/lib/storage";
import { checkRoleReminder } from "../sign_up_steps/sign_up_steps_slice";
import { authApi, IUser } from "./auth_api";
import { IRoles, ROLE_IDS } from "./roles/roles_consts";
import { IAxiosErr } from "../organizations/organization_slice";
import { stepsId } from "../../components/sign_up/newSignUp";
import { logout } from "./auth_actions";
import {
  fetchMyJourneyData,
  setTimerAction,
} from "../entities/my_journey/my_journey_slice";
import {
  receiveCancelAtPeriod,
  receiveCustomerAction,
  receiveSubscription,
} from "../entities/stripe/stripe_slice";
import {
  clearErrors,
  receiveErrors,
} from "../errors/session/session_errors_actions";
import { changeFilter } from "../ui/filters/filters_slice";
import {
  disableButton,
  reqPWResetMessage,
  resetPWMessage,
} from "../ui/form/form_slice";
import { PERMISSIONS } from "./permissions/permissions_consts";
import { roleFromRoleId } from "./roles/roles_helpers";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
const validateTokenAction = { type: VALIDATE_TOKEN };

interface IRegisterUser {
  email: string;
  firstName: string;
  lastName: string;
  role: IRoles;

  teacherSingUp?: boolean;
}

export const registerNextStepTeacherSignUp = createAsyncThunk<
  void,
  { email: string; fName?: string; lName?: string },
  { state: IState }
>(
  "auth/registerTeacherNextStep",
  async ({ email, fName, lName }, { dispatch }) => {
    dispatch(setUserDataSignup({ email, fname: fName, lname: lName }));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
        stepId: stepsId.confirmEmail,
      }),
    );
  },
);

export const registerTeacherSignUp = createAsyncThunk<
  void,
  IRegisterUser,
  { state: IState }
>(
  "auth/registerTeacherSignUp",
  async (
    { email, firstName, lastName, role },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const result = await dispatch(
        registerUser({ email, firstName, lastName, role }),
      );

      if (isActionRejectedHelper(result)) {
        throw result;
      }

      dispatch(
        registerNextStepTeacherSignUp({
          email,
          fName: firstName,
          lName: lastName,
        }),
      );
    } catch (err) {
      return rejectWithValue((err as IAxiosErr).response.data);
    }
  },
);

export const setPasswordTeacherSignUp = createAsyncThunk<
  void,
  { password: string; passwordConfirmation: string },
  { state: IState }
>(
  "auth/setPasswordTeacherSignUp",
  async ({ password, passwordConfirmation }, { dispatch }) => {
    const result = await dispatch(resetPW({ password, passwordConfirmation }));

    if (isActionRejectedHelper(result)) {
      return;
    }

    dispatch(
      stepListActions.nextStep({
        stepListId: STEP_LIST_IDS.signUpStepList,
      }),
    );
  },
);

export const registerUser = createAsyncThunk<
  void,
  IRegisterUser,
  { state: IState }
>(
  "auth/registerUser",
  async (
    { email, firstName, lastName, role },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { promo } = getState().auth;
      dispatch(disableButton(true));

      await authApi.registerUser({ email, firstName, lastName, role, promo });
      dispatch(
        reqPWResetMessage(
          `An email with a link to confirm your account was sent to ${email}.`,
        ),
      );

      analyticUtil.sendSignUpEvent();
    } catch (err) {
      const message = (err as IAxiosErr).response.data.errors;
      if (message) {
        dispatch(receiveErrors({ label: "signup", errors: message }));
      }
      return rejectWithValue((err as IAxiosErr).response.data);
    } finally {
      dispatch(disableButton(false));
    }
  },
);

interface IHandleLogin {
  email: string;
  password: string;
}
export const handleLogin = createAsyncThunk<
  void,
  IHandleLogin,
  { state: IState }
>("auth/handleLogin", async ({ email, password }, { dispatch }) => {
  dispatch(disableButton(true));
  authApi
    .loginUser({ email, password })
    .then((res) => {
      const {
        data: { data: user },
        headers,
      } = res;
      timer.createTimer(user.id);

      dispatch(setTimerAction(timer.timerId));
      dispatch(loginUser({ user, headers }));
    })
    .catch((err) => {
      const message = err.response?.data?.errors;
      dispatch(receiveErrors({ label: "login", errors: message }));
      dispatch(disableButton(false));
    });
});

interface IHandleLogoutParams {
  close?: () => void;
}

export const handleLogout = createAsyncThunk<
  void,
  IHandleLogoutParams,
  { state: IState }
>("auth/handleLogout", async ({ close }, { dispatch, getState }) => {
  const isAuth = store.getState().auth.user;

  if (isAuth) {
    storage.removeItem("persist:root");
    authApi
      .logoutUser()
      .then((res) => {
        const location = getLocation(getState());

        timer.clearTimer();
        dispatch(setTimerAction(timer.timerId));

        if (location.pathname !== "/") {
          dispatch(push("/"));
        }
        if (close) {
          close();
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        dispatch(logout());
        dispatch(changeFilter({ filter: "fetchedCustomer", value: false }));
        dispatch(clearPermissions());
      });
  }
});

export const reqPWReset = createAsyncThunk(
  "auth/reqPWReset",
  async ({ email }: { email: string }, { dispatch }) => {
    dispatch(disableButton(true));
    authApi
      .requestPasswordReset(email)
      .then((res) => {
        dispatch(clearErrors());
        dispatch(reqPWResetMessage(res.data.message || null));
      })
      .catch((err) => {
        const message = err.response.data.errors;
        dispatch(reqPWResetMessage(null));
        dispatch(receiveErrors({ label: "reqPWReset", errors: message }));
      })
      .then(() => {
        dispatch(disableButton(false));
      });
  },
);

interface IResetPW {
  password: string;
  passwordConfirmation: string;
}

export const resetPW = createAsyncThunk<void, IResetPW, { state: IState }>(
  "auth/resetPW",
  async ({ password, passwordConfirmation }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(disableButton(true));
      const result = await authApi.resetPassword(
        password,
        passwordConfirmation,
      );
      const {
        data: { data: user },
        headers,
      } = result;
      if (DEV) {
        console.info("RESP: ", result);
      }
      dispatch(resetPWMessage(result.data.message || null));
      dispatch(clearErrors());
      dispatch(loginUser({ user, headers }));
    } catch (err) {
      const message = (err as IAxiosErr).response?.data?.errors || "";
      dispatch(resetPWMessage(null));
      dispatch(receiveErrors({ label: "resetPW", errors: message }));

      if (DEV) {
        console.info("ERR: ", err);
      }
      return rejectWithValue((err as IAxiosErr).response.data);
    } finally {
      dispatch(disableButton(false));
    }
  },
);

export const validateToken = createAsyncThunk<void, void, { state: IState }>(
  "auth/validateToken",
  async (_, { dispatch }) => {
    dispatch(validateTokenAction);
    authApi
      .validateToken()
      .then((res) => {
        const user = res.data.data;

        timer.createTimer(user.id);

        dispatch(setTimerAction(timer.timerId));
        dispatch(loginUser({ user }));
      })
      .catch((err) => {
        dispatch(handleLogout({}));
        return null;
      })
      .then(() => {
        dispatch(changeFilter({ filter: "userFetched", value: true }));
      });
  },
);

interface ILoginUser {
  user: IUser;
  headers?: unknown;
}

const mixpanel_init = (user: IUser) => {
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN &&
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
      debug: false,
      persistence: "localStorage",
    });
  mixpanel.identify(user.uid);

  if (user.role_id) {
    mixpanel.people.set({ role: roleFromRoleId(user.role_id) });
  }
};

export const loginUser = createAsyncThunk<void, ILoginUser, { state: IState }>(
  "auth/loginUser",
  // @ts-ignore
  async ({ user, headers, emulatedRole }, { dispatch }) => {
    dispatch(login({ user, _headers: headers }));
    dispatch(fetchUserOrganizations({ userId: user.id }));
    dispatch(fetchUserFeedback({ studentId: user.id }));
    dispatch(fetchUserMediaRecordings({ userId: user.id }));
    dispatch(fetchUserCourses({ userId: user.id }));
    dispatch(fetchUserEmails({ userId: user.id }));
    dispatch(fetchMyJourneyData({ userId: user.id }));
    if (emulatedRole) {
      dispatch(fetchPermissionsByRole(emulatedRole));
    } else {
      dispatch(fetchPermissions());
    }
    mixpanel_init(user);
  },
);

/**
 *  don't forget to dispatch re-login action after changing email to fetch latest data
 */
export const updateUserPrimaryEmail = (userId: number, email: string) => {
  userEmailApi.updatePrimaryEmail(userId, email);
};

export const fetchUserEmails = createAsyncThunk(
  "auth/fetchUserEmails",
  async (options: { userId: number }) => {
    const {
      data: { emails },
    } = await userEmailApi.fetchEmails(options.userId);
    return emails;
  },
);

interface IOauthLogin {
  token: string;
  provider: string;
  page?: string;
}

export const oauthLogin = createAsyncThunk<
  void,
  IOauthLogin,
  { state: IState }
>(
  "auth/oauthLogin",
  async ({ token, provider, page }, { dispatch, getState }) => {
    const { promo } = getState().auth;
    const mobile = navigator.userAgent.match(/Mobile|iOSMoosikoMobileApp/);
    const default_role = mobile
      ? ROLE_IDS.FREE_SONGWRITING
      : ROLE_IDS.FREE_USER;

    dispatch(disableButton(true));

    authApi
      .loginOauthUser({ token, provider, page, promo, default_role })
      .then((res) => {
        const {
          data: { data: user },
          headers,
        } = res;
        timer.createTimer(user.id);
        dispatch(setTimerAction(timer.timerId));
        dispatch(loginUser({ user, headers }));
      })
      .catch((err) => {
        const message = err.response.data.errors;
        dispatch(receiveErrors({ label: "login", errors: message }));
        dispatch(disableButton(false));
      });
  },
);

export interface IRolePermissions {
  role: IRoles;
  permissions: string[];
}
export const fetchPermissions = createAsyncThunk<
  IRolePermissions | void,
  void,
  { state: IState }
>("auth/getPermissions", async (_, { dispatch, getState }) => {
  try {
    dispatch(disableButton(true));
    const { data } = await userApi.fetchPermissions();

    dispatch(checkRoleReminder({ role: data.role }));

    if (navigator.userAgent.match(/iOSMoosikoMobileApp/)) {
      data.permissions = data.permissions.filter(
        (p) =>
          p === PERMISSIONS.SONGWRITING ||
          p === PERMISSIONS.UNLIMITED_SONGWRITING,
      );
    }
    mixpanel.track("FetchPermissions", { role: data.role });
    return { role: data.role, permissions: data.permissions };
  } finally {
    dispatch(disableButton(false));
  }
});

export const fetchPermissionsByRole = createAsyncThunk<
  IRolePermissions | void,
  IRoles,
  { state: IState }
>("auth/getPermissionsByRole", async (role, { dispatch }) => {
  try {
    // @ts-ignore
    const { data } = await userApi.fetchPermissionsByRole(role);
    return { role: data.role, permissions: data.permissions };
  } finally {
    dispatch(disableButton(false));
  }
});

export const updateUserRole = createAsyncThunk<
  IRolePermissions,
  { email?: string; roleName: IRoles },
  { state: IState }
>("auth/updateUserRole", async ({ email, roleName }) => {
  const { data } = await userApi.updateUserRole({ email, roleName });

  return { role: data.role, permissions: data.permissions };
});

export type StripeCustomer = Stripe.Customer & {
  sources: { data: { brand: string; last4: number }[] };
};

export const deleteUserProfile = createAsyncThunk<
  void,
  { user_id: number },
  { state: IState }
>("auth/deleteUserProfile", async ({ user_id }, { dispatch }) => {
  userApi
    .deleteUserProfile({ user_id })
    .then(() => {
      dispatch(logout());
    })
    .catch((err) => {
      console.error(err);
    });
});

interface IAuthState {
  emails: IUserEmail[];
  user: IUser | null;
  customer: StripeCustomer | null;
  promo: string | null;
  promoRole: number | null;
  role: IRoles | null;
  permissions: string[];
  loginType: string | null;
}

const initialState: IAuthState = {
  emails: [],
  user: null,
  customer: null,
  promo: null,
  promoRole: null,
  role: null,
  permissions: [
    PERMISSIONS.ACCESS_TO_GUITAR,
    PERMISSIONS.ACCESS_TO_UKULELE,
    PERMISSIONS.SONGWRITING,
  ],
  loginType: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state,
      //* headers used for auth middleware */
      action: PayloadAction<{ user: IUser; _headers?: unknown }>,
    ) => {
      state.user = action.payload.user;
      state.promo = null;
    },
    applyPromo: (state, action) => {
      state.promo = action.payload;
    },
    applyPromoRole: (state, action) => {
      state.promoRole = action.payload;
    },
    applyLoginType: (state, action) => {
      state.loginType = action.payload;
    },
    setSubscriptionEnd: (state, action) => {
      if (state.user) {
        state.user.subscription_end = action.payload;
      }
    },
    clearPermissions: (state) => {
      state.permissions = [
        PERMISSIONS.ACCESS_TO_GUITAR,
        PERMISSIONS.ACCESS_TO_UKULELE,
        PERMISSIONS.SONGWRITING,
      ];
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(receiveCancelAtPeriod, (state, action) => {
      if (state.user) {
        state.user.cancelAtPeriodEnd = action.payload.cancelAtPeriodEnd;
      }
    });
    builder.addCase(receiveSubscription, (state, action) => {
      if (state.user) {
        state.user.subscribed = action.payload.subscribed;
        state.user.subscription_end = action.payload.subscription_end;
        state.user.cancelAtPeriodEnd = action.payload.cancelAtPeriodEnd;
      }
    });
    builder.addCase(fetchUserEmails.fulfilled, (state, action) => {
      state.emails = action.payload;
    });
    builder.addCase(receiveCustomerAction, (state, action) => {
      state.customer = action.payload;
    });
    builder.addCase(logout, (state) => {
      state.user = null;
      state.role = null;
      state.customer = null;
    });
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      if (action.payload) {
        state.role = action.payload.role;
        state.permissions = action.payload.permissions;
      }
    });
    builder.addCase(fetchPermissionsByRole.fulfilled, (state, action) => {
      if (action.payload) {
        state.role = action.payload.role;
        state.permissions = action.payload.permissions;
      }
    });
    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      if (action.payload) {
        state.role = action.payload.role;
        state.permissions = action.payload.permissions;
      }
    });
    builder.addCase(fetchPermissions.rejected, (state) => {
      state.role = null;
      state.permissions = [];
    });
  },
});

export const {
  reducer: authReducer,
  actions: {
    login,
    applyPromo,
    applyPromoRole,
    setSubscriptionEnd,
    applyLoginType,
    clearPermissions,
    setPermissions,
  },
} = authSlice;
