/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { SENSITIVE_SCOPES } from "constants/keys";
import { listGoogleClassroomCourses } from "redux/import_students/google_classroom/google_classroom_students_slice";
import { ExternalResource } from "../add_from_external_resources";

import { CourseList } from "../course_list/course_list";
import { CourseStudentsList } from "../course_students_table/course_students_table";

export enum GoogleClassroomSteps {
  COURSE_LIST = 1,
  STUDENTS_LIST = 2,
}

export const AddFromGoogleClassroom = () => {
  const [userToken, setUserToken] = useState<string>();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<GoogleClassroomSteps>(
    GoogleClassroomSteps.COURSE_LIST,
  );

  useEffect(() => {
    if (userToken) {
      dispatch(listGoogleClassroomCourses({ token: userToken }));
    }
  }, [dispatch, userToken]);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setUserToken(tokenResponse.access_token);
    },
    scope: SENSITIVE_SCOPES,
  });

  useEffect(() => {
    login();
  }, [login]);

  return currentStep === GoogleClassroomSteps.COURSE_LIST ? (
    <CourseList
      type={ExternalResource.GOOGLE_CLASSROOM}
      setCurrentStep={setCurrentStep}
      userToken={userToken}
    />
  ) : (
    <CourseStudentsList
      type={ExternalResource.GOOGLE_CLASSROOM}
      setCurrentStep={setCurrentStep}
    />
  );
};
