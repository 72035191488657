import { createSelector } from "reselect";
import { IState } from "redux/store";

export const getAuthSelector = (state: IState) => state.auth;

export const getUserSelector = (state: IState) => state.auth.user;

export const completedLessonsSelector = (state: IState) => {
  return [...Object.values(state.entities.userLessons)].filter((lesson) => {
    return lesson.progress === 100;
  }).length;
};

export const isAuthorizedSelector = createSelector(
  [getUserSelector],
  (user) => !!user,
);

export const getUserIdSelector = createSelector([getUserSelector], (user) => {
  return user?.id;
});

export const getUserFirstNameSelector = createSelector(
  [getUserSelector],
  (user) => {
    return user ? user.fname : null;
  },
);

export const getIsInstructorSelector = createSelector(
  [getUserSelector],
  (user) => user?.instructor,
);

export const getIsPromoSelector = createSelector(
  [getUserSelector],
  (user) => user?.promo,
);

export const getPromoRole = createSelector(
  [getAuthSelector],
  (auth) => auth?.promoRole,
);

export const getLogInType = createSelector(
  [getAuthSelector],
  (auth) => auth?.loginType,
);

export const getUserEmailSelector = createSelector(
  [getUserSelector],
  (user) => {
    return user ? user.email : null;
  },
);

export const getUserProviderSelector = createSelector(
  [getUserSelector],
  (user) => {
    return user ? user.provider : null;
  },
);

export const cancelAtPeriodEndSelector = createSelector(
  [getUserSelector],
  (user) => {
    return user ? user.cancelAtPeriodEnd : null;
  },
);

export const getMemberSubscriptionEnd = createSelector(
  [getUserSelector],
  (user) => {
    if (!user) {
      return null;
    }
    return user.subscription_end;
  },
);

export const getUserEmails = (state: IState) => state.auth.emails;

export const getPromoRoleId = (state: IState) => state.auth.promoRole;
