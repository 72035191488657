/* eslint-disable no-magic-numbers */

import { useCallback, useMemo, useRef, useState } from "react";
// TODO: Find or build typscript definitions for the following imports
// @ts-ignore
import EssentiaExtractor from "essentia.js/dist/essentia.js-extractor.es.js";
// @ts-ignore
import { EssentiaWASM } from "essentia.js/dist/essentia-wasm.es.js";

let rmsLevel = 0.05;

export interface INoteData {
  note: string;
  min: number;
  max: number;
  check: boolean;
}

const useMicrophone = () => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);

  // const [noteSettings, setNoteSettings] = useState<INoteData[]>([]);

  const [rmsValueState, setRmsValueState] = useState(0);
  const [hpcpDataState, setHpcpDataState] = useState<number[]>([]);

  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const isMicrophoneOnRef = useRef(false);
  const noteSettingsRef = useRef<INoteData[]>([]);

  const essentiaExtractor = useMemo(
    () => new EssentiaExtractor(EssentiaWASM),
    [],
  );

  const processAudioBuffer = useCallback(
    (AudioBuffer: AudioBuffer, chordCounterFunc: any) => {
      // TODO - Fix any type
      const getHpcpDataForNote = (note: string, hpcpData: any) => {
        if (note === "A") {
          return hpcpData[0];
        }
        if (note === "A#") {
          return hpcpData[1];
        }
        if (note === "B") {
          return hpcpData[2];
        }
        if (note === "C") {
          return hpcpData[3];
        }
        if (note === "C#") {
          return hpcpData[4];
        }
        if (note === "D") {
          return hpcpData[5];
        }
        if (note === "D#") {
          return hpcpData[6];
        }
        if (note === "E") {
          return hpcpData[7];
        }
        if (note === "F") {
          return hpcpData[8];
        }
        if (note === "F#") {
          return hpcpData[9];
        }
        if (note === "G") {
          return hpcpData[10];
        }
        if (note === "G#") {
          return hpcpData[11];
        }
      };

      const vectorSignal = essentiaExtractor.arrayToVector(
        AudioBuffer.getChannelData(0),
      );

      const rmsValue = essentiaExtractor.RMS(vectorSignal);

      setRmsValueState(rmsValue.rms);

      if (rmsValue.rms > rmsLevel) {
        const hpcpData = essentiaExtractor.hpcpExtractor(
          AudioBuffer.getChannelData(0),
        );
        setHpcpDataState(hpcpData);

        let pass = true;
        const filtered = noteSettingsRef.current.filter((note) => {
          return note.check;
        });

        if (filtered.length > 0) {
          filtered.forEach((note) => {
            const hpcpValueForNote = getHpcpDataForNote(note.note, hpcpData);

            if (hpcpValueForNote < note.min || hpcpValueForNote > note.max) {
              pass = false;
            }
          });
        } else {
          pass = false;
        }

        pass && chordCounterFunc();
      }
    },

    [essentiaExtractor],
  );

  const rmsValue = rmsValueState;
  const hpcpData = hpcpDataState;

  const startMicrophone = useCallback(
    async (chordCounterFunc, rmsValueThreshold, noteData) => {
      if (!isMicrophoneOn) {
        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          setStream(audioStream);
          setIsMicrophoneOn(true);
          rmsLevel = rmsValueThreshold;
          isMicrophoneOnRef.current = true;

          noteSettingsRef.current = noteData;

          const audioContext = new AudioContext();
          audioContextRef.current = audioContext;

          const analyser = audioContext.createAnalyser();
          analyser.fftSize = 2048;
          analyserRef.current = analyser;

          const source = audioContext.createMediaStreamSource(audioStream);
          source.connect(analyser);

          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Float32Array(bufferLength);

          const captureAudio = () => {
            if (!isMicrophoneOnRef.current) {
              return;
            }

            analyser.getFloatTimeDomainData(dataArray);
            const audioBuffer = audioContext.createBuffer(
              1,
              bufferLength,
              audioContext.sampleRate,
            );
            audioBuffer.copyToChannel(dataArray, 0);
            processAudioBuffer(audioBuffer, chordCounterFunc);
            requestAnimationFrame(captureAudio);
          };

          captureAudio();
        } catch (error) {
          console.error("Error starting microphone:", error);
        }
      }
    },
    [isMicrophoneOn, processAudioBuffer],
  );

  const stopMicrophone = useCallback(() => {
    if (isMicrophoneOn && stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
      setIsMicrophoneOn(false);
      isMicrophoneOnRef.current = false;
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
    }
  }, [isMicrophoneOn, stream]);

  return {
    startMicrophone,
    stopMicrophone,
    isMicrophoneOn,
    rmsValue,
    hpcpData,
  };
};

export default useMicrophone;
