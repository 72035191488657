import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  listCoursesHelper,
  listUsersFromChosenCourseHelper,
} from "helpers/google_classroom_auth_helper";
import { IState } from "../../store";
import {
  IClassroomStudent,
  IClassroomCourse,
} from "../../../types/helpers/classroomTypes";

interface IClassroomImportState {
  courses: IClassroomCourse[];
  students: IClassroomStudent[];
  isFetchingData: boolean;
}

const initialState: IClassroomImportState = {
  courses: [],
  students: [],
  isFetchingData: false,
};

export const fetchingData = createAction<boolean>("classroom/fetchingData");

export const listUsersFromGoogleClassroomCourse = createAsyncThunk<
  { students: IClassroomStudent[] },
  { courseId: string; token?: string },
  { state: IState }
>(
  "classroom/listUsersFromClassroomCourse",
  async ({ courseId, token }, { dispatch }) => {
    dispatch(fetchingData(true));
    const result = await listUsersFromChosenCourseHelper(courseId, token);
    dispatch(fetchingData(false));

    return { students: result };
  },
);

export const listGoogleClassroomCourses = createAsyncThunk<
  { courses: IClassroomCourse[] },
  { pageSize?: number; token?: string },
  { state: IState }
>(
  "classroom/listGoogleClassroomCourses",
  async ({ pageSize = 10, token }, { dispatch }) => {
    dispatch(fetchingData(true));
    const result = await listCoursesHelper(token, pageSize);
    dispatch(fetchingData(false));

    return { courses: result };
  },
);

const classroomImportSlice = createSlice({
  name: "classroom",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchingData, (state, action: PayloadAction<boolean>) => {
      state.isFetchingData = action.payload;
    });
    builder.addCase(
      listGoogleClassroomCourses.fulfilled,
      (state, action: PayloadAction<{ courses: IClassroomCourse[] }>) => {
        state.courses = action.payload.courses || [];
      },
    );
    builder.addCase(
      listUsersFromGoogleClassroomCourse.fulfilled,
      (state, action: PayloadAction<{ students: IClassroomStudent[] }>) => {
        state.students = action.payload.students || [];
      },
    );
  },
});

export const { reducer: classroomImportReducer } = classroomImportSlice;
